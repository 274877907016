import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllSchoolClub,getSchoolOrClubDetail,getSampleCSV,uploadSchoolClubCSV } from "../../api/schoolclubManagement";



// Thunk action for get all user
export const getAllSchoolClubAction = createAsyncThunk(
  "school-club/getAll",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllSchoolClub(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getAllDetailOfSchoolClubAction = createAsyncThunk(
  "school-club/detail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getSchoolOrClubDetail(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSampleCSVAction = createAsyncThunk(
  "sample/csv",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getSampleCSV(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postCSVAction = createAsyncThunk(
  "upload/csv",
  async (data, { rejectWithValue }) => {
    try {
      const response = await uploadSchoolClubCSV(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);







