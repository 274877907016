import { get, post, put, del } from "../../helpers/apiWrapper";

// POST API for get school/club
export const getAllSchoolClub = async (data) => {
  const config = {
    params: data,
  };
  return await get(`/user/all`, config);
};

export const getSchoolOrClubDetail = async (data) => {
  return await get(`/user/${data?.id}`);
};

export const getSampleCSV = async (data) => {
 const config={
  params: data
 }
  return await post(`/user/simple-csv`,config);
};

export const uploadSchoolClubCSV = async (data) => {
  const config={
   data: data
  }
   return await post(`/user/upload-csv`,config);
 };

