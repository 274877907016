import { createSlice } from "@reduxjs/toolkit";
import {
  createChildAction,
  deleteChildAction,
  getChildAction,
  getAllSchoolAction,
  getChildDetailAction,
  editChildAction,
  getSchoolDropdownAction,
  getClubDropdownAction,
} from "./actions";
import { notification } from "antd";
import { act } from "react";

const initialState = {
  createdChild: {},
  loadingCreate: false,
  errorCreate: null,

  editedChild: {},
  loadingEdit: false,
  errorEdit: null,

  getAllChildren: {},
  loadingAll: false,
  errorAll: null,

  deletedChild: {},
  loadingDelete: false,
  errorDelete: null,

  schoolList: {},
  loadingSchool: false,
  errorSchool: null,

  childDetail: {},
  loadingChildDetail: false,
  errorChildDetail: null,

  schoolForDrop: {},
  loadingSchoolDrop: false,
  errorSchoolDrop: null,

  clubForDrop: {},
  loadingClubDrop: false,
  errorClubDrop: null,
};
const childSlice = createSlice({
  name: "child",
  initialState,
  reducers: {
    clearChildDetailData: (state) => {
      state.childDetail = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createChildAction.pending, (state) => {
        state.loadingCreate = true;
        state.errorCreate = null;
      })
      .addCase(createChildAction.fulfilled, (state, action) => {
        console.log(action.payload, "payloaddd");
        state.loadingCreate = false;
        state.createdChild = action.payload;
      })
      .addCase(createChildAction.rejected, (state, action) => {
        state.loadingCreate = false;
        state.errorCreate = action.payload;
      })
      .addCase(getChildAction.pending, (state) => {
        state.loadingAll = true;
        state.errorAll = null;
      })
      .addCase(getChildAction.fulfilled, (state, action) => {
        console.log("Takshak action", action.payload);
        state.loadingAll = false;
        state.getAllChildren = action.payload;
      })
      .addCase(getChildAction.rejected, (state, action) => {
        state.loadingAll = false;
        state.errorAll = action.payload;
      })
      .addCase(deleteChildAction.pending, (state) => {
        state.loadingDelete = true;
        state.errorDelete = null;
      })
      .addCase(deleteChildAction.fulfilled, (state, action) => {
        state.loadingDelete = false;
        state.deletedChild = action.payload;
        console.log("action.payload", action.payload);
        console.log("state", state);
        state.getAllChildren.children = state?.getAllChildren?.children?.filter(
          (groupId) => groupId?.id !== action?.payload?.data?.id
        );
        state.getAllChildren.meta.pagination.totalChildren -= 1;
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(deleteChildAction.rejected, (state, action) => {
        state.loadingDelete = false;
        state.errorDelete = action.payload;
      })
      .addCase(getAllSchoolAction.pending, (state) => {
        state.loadingSchool = true;
        state.errorSchool = null;
      })
      .addCase(getAllSchoolAction.fulfilled, (state, action) => {
        state.loadingSchool = false;
        state.schoolList = action.payload;
      })
      .addCase(getAllSchoolAction.rejected, (state, action) => {
        state.loadingSchool = false;
        state.errorSchool = action.payload;
      })
      .addCase(getChildDetailAction.pending, (state) => {
        state.loadingChildDetail = true;
        state.errorChildDetail = null;
      })
      .addCase(getChildDetailAction.fulfilled, (state, action) => {
        state.loadingChildDetail = false;
        state.childDetail = action.payload;
      })
      .addCase(getChildDetailAction.rejected, (state, action) => {
        state.loadingChildDetail = false;
        state.errorChildDetail = action.payload;
      })
      .addCase(editChildAction.pending, (state) => {
        state.loadingEdit = true;
        state.errorEdit = null;
      })
      .addCase(editChildAction.fulfilled, (state, action) => {
        console.log(action.payload, "tessss");
        state.loadingEdit = false;
        state.editedChild = action.payload;
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(editChildAction.rejected, (state, action) => {
        state.loadingEdit = false;
        state.errorEdit = action.payload;
      })
      .addCase(getSchoolDropdownAction.pending, (state) => {
        state.loadingSchoolDrop = true;
        state.errorSchoolDrop = null;
      })
      .addCase(getSchoolDropdownAction.fulfilled, (state, action) => {
        state.loadingSchoolDrop = false;
        state.schoolForDrop = action.payload;
      })
      .addCase(getSchoolDropdownAction.rejected, (state, action) => {
        state.loadingSchoolDrop = false;
        state.errorSchoolDrop = action.payload;
      })
      .addCase(getClubDropdownAction.pending, (state) => {
        state.loadingClubDrop = true;
        state.errorClubDrop = null;
      })
      .addCase(getClubDropdownAction.fulfilled, (state, action) => {
        state.loadingClubDrop = false;
        state.clubForDrop = action.payload;
      })
      .addCase(getClubDropdownAction.rejected, (state, action) => {
        state.loadingClubDrop = false;
        state.errorClubDrop = action.payload;
      });
  },
});
export default childSlice.reducer;
export const { clearChildDetailData } = childSlice.actions;
