import { get, post, put, del } from "../../helpers/apiWrapper";

// POST API for createUser
export const getAllChildForReport = async (userData) => {
  const config = {
    params: userData,
  };
  return await get(`/child/getall`, config);
};

export const getTimesofReport = async (userData) => {
  const config={
    params: {
      page: userData?.page,
      date: userData?.date
    }
  }
  return await get(
    `/child/getReport/${userData?.categoryId}/${userData?.childId}`,config
  );
};

export const getsInitialReport = async (userData) => {
  const config = {
    data: {
      date: userData?.id,
    },
  };

  return await get(`/child/getReportWithQueAns/${userData?.id}`);
};

export const getDownlolReport = async (userData) => {
  return await get(
    `/child/get-report-pdf/${userData?.categoryId}/${userData?.id}`
  );
};
