import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteClass,
  getAllClass,
  getAllMemberSchool,
  editClass,
  createClass,
  getAllPlayerForSpecificClass,
  removePlayerFromClass,
  getAllDetilsofClass,
  getSampleCSVForTeamClass,
  uploadTeamClassCSV
} from "../../api/classTeamManagement";

export const getClassTeamAction = createAsyncThunk(
  "class/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllClass(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createClassAction = createAsyncThunk(
  "class/create",
  async (childData, { rejectWithValue }) => {
    try {
      const response = await createClass(childData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteTeamAction = createAsyncThunk(
  "team/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deleteClass(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllSchoolMemberAction = createAsyncThunk(
  "schoolMember/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllMemberSchool(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPlayerSpecificClassAction = createAsyncThunk(
  "playerSpecifc/class",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllPlayerForSpecificClass(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const editClassAction = createAsyncThunk(
  "class/edit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await editClass(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removePlayerAction = createAsyncThunk(
  "player/Remove",
  async (data, { rejectWithValue }) => {
    try {
      const response = await removePlayerFromClass(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const classDetailAction = createAsyncThunk(
  "class/detail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllDetilsofClass(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSampleCSVForTeamClassAction = createAsyncThunk(
  "sample/csvForTeamClass",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getSampleCSVForTeamClass(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postCSVForTeamClassAction = createAsyncThunk(
  "post/csvForTeamClass",
  async (data, { rejectWithValue }) => {
    try {
      const response = await uploadTeamClassCSV(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

