import { createSlice } from "@reduxjs/toolkit";
import {
  createNewsAction,
  editNewsAction,
  getAllNewsAction,
  deleteNewsAction,
  getSingleNewsAction
} from "./actions";
import { notification } from "antd";

const initialState = {
  createdNews: {},
  loadingCreate: false,
  errorCreate: null,

  editedNews: {},
  loadingEdit: false,
  errorEdit: null,

  allNews: [],
  loadingAll: false,
  errorAll: null,

  deletedNews: {},
  loadingDelete: false,
  errorDelete: null,

  singleNews:{},
  loadingSingleNews: false,
  errorSingleNews: null
};
const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createNewsAction.pending, (state) => {
        state.loadingCreate = true;
        state.errorCreate = null;
      })
      .addCase(createNewsAction.fulfilled, (state, action) => {
        state.loadingCreate = false;
        state.createdNews = action.payload;
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(createNewsAction.rejected, (state, action) => {
        state.loadingCreate = false;
        state.errorCreate = action.payload;
      })
      .addCase(editNewsAction.pending, (state) => {
        state.loadingEdit = true;
        state.errorEdit = null;
      })
      .addCase(editNewsAction.fulfilled, (state, action) => {
        state.loadingEdit = false;
        state.editedNews = action.payload;
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(editNewsAction.rejected, (state, action) => {
        state.loadingEdit = false;
        state.errorEdit = action.payload;
      })
      .addCase(getAllNewsAction.pending, (state) => {
        state.loadingAll = true;
        state.errorAll = null;
      })
      .addCase(getAllNewsAction.fulfilled, (state, action) => {
        state.loadingAll = false;
        state.allNews = action.payload;
      })
      .addCase(getAllNewsAction.rejected, (state, action) => {
        state.loadingAll = false;
        state.errorAll = action.payload;
      })
      .addCase(deleteNewsAction.pending, (state) => {
        state.loadingDelete = true;
        state.errorDelete = null;
      })
      .addCase(deleteNewsAction.fulfilled, (state, action) => {
        console.log(action?.payload);
        state.loadingDelete = false;
        state.deletedNews = action.payload;
        state.allNews.data = state.allNews.data.filter(
          (groupId) => groupId?.id !== action?.payload?.data?.id
        );
        state.allNews.meta.pagination.totalBlogs -= 1;
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(deleteNewsAction.rejected, (state, action) => {
        state.loadingDelete = false;
        state.errorDelete = action.payload;
      })
      .addCase(getSingleNewsAction.pending, (state) => {
        state.loadingSingleNews = true;
        state.errorSingleNews = null;
      })
      .addCase(getSingleNewsAction.fulfilled, (state, action) => {
        state.loadingSingleNews = false;
        state.singleNews = action.payload;
      })
      .addCase(getSingleNewsAction.rejected, (state, action) => {
        state.loadingSingleNews = false;
        state.errorSingleNews = action.payload;
      })
  },
});
export default newsSlice.reducer;
