import { get, post, put, del } from "../../helpers/apiWrapper";

// POST API for createUser
export const createFoundation = async (userData) => {
  const config = {
    data: userData,
  };
  return await post(`/foundation/create`, config);
};

// patch API for Edit USer
export const editFoundation = async ({ formData, data }) => {
  const config = {
    data: formData,
  };
  return await put(`/foundation/edit/${data?.id}`, config);
};

// get API for get all user
export const getAllFoundation = async (data) => {
  const config = {
    params: data,
  };
  return await get(`/foundation/all`, config);
};

// delete Employee
export const deleteFoundation = async (data) => {
  return await del(`/foundation/delete/${data?.id}`);
};

export const getFoundationDetail = async (data) => {
  return await post(`/foundation/assign-foundation/${data?.id}`);
};
