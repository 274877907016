import { createSlice } from "@reduxjs/toolkit";
import {
  getAllDetailOfSchoolClubAction,
  getAllSchoolClubAction,
  getSampleCSVAction,
  postCSVAction,
} from "./actions";
import { notification } from "antd";

const initialState = {
  allSchoolClub: [],
  loadingAllSchoolClub: false,
  errorAllSchoolClub: null,

  schoolClubDetail: {},
  loadingSchoolClubDetail: false,
  errorSchoolClubDetail: null,

  sampleCSVForSchoolClub: {},
  loadingSampleCSV: false,
  errorSampleCSV: null,

  postCSVForSchoolClub: {},
  loadingPostCSV: false,
  errorPostCSV: null,
};
const schoolclubManagementSlice = createSlice({
  name: "SchoolClubManagement",
  initialState,
  reducers: {
    clearschoolClubDetail: (state) => {
      state.schoolClubDetail = {};
    },
    clearPostCSVData: (state) => {
      state.postCSVForSchoolClub = {};
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getAllSchoolClubAction.pending, (state) => {
        state.loadingAllSchoolClub = true;
        state.errorAllSchoolClub = null;
      })
      .addCase(getAllSchoolClubAction.fulfilled, (state, action) => {
        state.loadingAllSchoolClub = false;
        state.allSchoolClub = action.payload;
      })
      .addCase(getAllSchoolClubAction.rejected, (state, action) => {
        state.loadingAllSchoolClub = false;
        state.errorAllSchoolClub = action.payload;
      })
      .addCase(getAllDetailOfSchoolClubAction.pending, (state) => {
        state.loadingSchoolClubDetail = true;
        state.errorSchoolClubDetail = null;
      })
      .addCase(getAllDetailOfSchoolClubAction.fulfilled, (state, action) => {
        state.loadingSchoolClubDetail = false;
        state.schoolClubDetail = action.payload.data;
      })
      .addCase(getAllDetailOfSchoolClubAction.rejected, (state, action) => {
        state.loadingSchoolClubDetail = false;
        state.errorSchoolClubDetail = action.payload;
      })
      .addCase(getSampleCSVAction.pending, (state) => {
        state.loadingSampleCSV = true;
        state.errorSampleCSV = null;
      })
      .addCase(getSampleCSVAction.fulfilled, (state, action) => {
        state.loadingSampleCSV = false;
        state.sampleCSVForSchoolClub = action.payload;
      })
      .addCase(getSampleCSVAction.rejected, (state, action) => {
        state.loadingSampleCSV = false;
        state.errorSampleCSV = action.payload;
      })
      .addCase(postCSVAction.pending, (state) => {
        state.loadingPostCSV = true;
        state.errorPostCSV = null;
      })
      .addCase(postCSVAction.fulfilled, (state, action) => {
        state.loadingPostCSV = false;
        state.postCSVForSchoolClub = action.payload;
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(postCSVAction.rejected, (state, action) => {
        state.loadingPostCSV = false;
        state.errorPostCSV = action.payload;
      });
  },
});
export default schoolclubManagementSlice.reducer;
export const { clearschoolClubDetail,clearPostCSVData } = schoolclubManagementSlice.actions;
