import { createSlice } from "@reduxjs/toolkit";
import {
  createCategoryAction,
  editCategoryAction,
  getAllCategoryAction,
  deleteCategoryAction,
} from "./actions";
import { notification } from "antd";

const initialState = {
  createdCategory: {},
  loadingCategory: false,
  errorCategory: null,

  editedCategory: {},
  loadingEditedCategory: false,
  errorEditedCategory: null,

  allCategory: [],
  loadingAllCategory: false,
  errorAllCategory: null,

  deletedCategory: {},
  loadingDeleteCategory: false,
  errorDeleteCategory: null,
};
const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCategoryAction.pending, (state) => {
        state.loadingCategory = true;
        state.errorCategory = null;
      })
      .addCase(createCategoryAction.fulfilled, (state, action) => {
        state.loadingCategory = false;
        state.createdCategory = action.payload;
        if (action?.payload?.meta?.success === true) {
          if (state.allCategory?.meta?.pagination?.currentPage == 1) {
            state.allCategory.data = [
              action?.payload?.data,
              ...state.allCategory.data,
            ];
          }
          state.allCategory.meta.pagination.totalCategories += 1;
        }
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(createCategoryAction.rejected, (state, action) => {
        state.loadingCategory = false;
        state.errorCategory = action.payload;
      })
      .addCase(editCategoryAction.pending, (state) => {
        state.loadingEditedCategory = true;
        state.errorEditedCategory = null;
      })
      .addCase(editCategoryAction.fulfilled, (state, action) => {
        state.loadingEditedCategory = false;
        state.editedCategory = action.payload;
        state.allCategory.data = state.allCategory.data.map((macro) =>
          macro.id === action.payload?.data?.id ? action.payload?.data : macro
        );
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(editCategoryAction.rejected, (state, action) => {
        state.loadingEditedCategory = false;
        state.errorEditedCategory = action.payload;
      })
      .addCase(getAllCategoryAction.pending, (state) => {
        state.loadingAllCategory = true;
        state.errorAllCategory = null;
      })
      .addCase(getAllCategoryAction.fulfilled, (state, action) => {
        state.loadingAllCategory = false;
        state.allCategory = action.payload;
      })
      .addCase(getAllCategoryAction.rejected, (state, action) => {
        state.loadingAllCategory = false;
        state.errorAll = action.payload;
      })
      .addCase(deleteCategoryAction.pending, (state) => {
        state.loadingDeleteCategory = true;
        state.errorDeleteCategory = null;
      })
      .addCase(deleteCategoryAction.fulfilled, (state, action) => {
        state.loadingDeleteCategory = false;
        state.deletedCategory = action.payload;
        state.allCategory.data = state.allCategory.data.filter(
          (groupId) => groupId?.id !== action?.payload?.data?.id
        );
        state.allCategory.meta.pagination.totalCategories -= 1;
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(deleteCategoryAction.rejected, (state, action) => {
        state.loadingDeleteCategory = false;
        state.errorDeleteCategory = action.payload;
      });
  },
});
export default categorySlice.reducer;
