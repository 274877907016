import { lazy } from "react";

const PublicRoutes = [
  {
    path: "/login",
    component: lazy(() => import("../pages/SignIn/index.js")),
    exact: true,
  },

  {
    path: "/forgotPassword",
    component: lazy(() => import("../pages/ForgetPassword/index.js")),
  },
  {
    path: "/forgotPassword/:id",
    component: lazy(() => import("../pages/Newpassword")),
  },
  {
    path: "/account-verify/:token",
    component: lazy(() => import("../pages/AccountVerify")),
  },
  {
    path: "*",
    component: lazy(() => import("../pages/Page404")),
    exact: true,
  },
  {
    path: "/faqs",
    component: lazy(() => import("../pages/faqpage")),
  },
  {
    path: "/:slug",
    component: lazy(() => import("../pages/CmsPage")),
  },
  {
    path: "/mission-and-impact",
    component: lazy(() => import("../pages/ShareSummary")),
  },
  {
    path: "/get-all-report",
    component: lazy(() => import("../pages/ContentPage")),
    exact: true,
   
    defaultAccess: true,
  }

  // All the public routes
];

const PrivateRoutes = [
  {
    path: "/",
    component: lazy(() => import("../pages/Dashboard")),
    exact: true,
    defaultAccess: true,
  },

  {
    path: "/employee",
    component: lazy(() => import("../pages/UserManegment")),
    exact: true,
    access: [
      "view-employee-management",
      "create-update-employee-management",
      "delete-employee-management",
    ],
  },

  {
    path: "/roles",
    component: lazy(() => import("../pages/Roles")),
    exact: true,
    access: [
      "view-roles-permissions",
      "create-update-roles-permission",
      "delete-roles-permissions",
    ],
  },
  {
    path: "/user-management",
    component: lazy(() => import("../pages/ParentchildManagement/Parent")),
    access: [
      "view-parent-child",
      "create-update-parent-child",
      "delete-parent-child",
    ],
  },
  {
    path: "/user-management/user-details/:id",
    component: lazy(() =>
      import("../pages/ParentchildManagement/ParentViewDetails")
    ),
    access: [
      "view-parent-child",
      "create-update-parent-child",
      "delete-parent-child",
    ],
  },
  {
    path: "/sports",
    component: lazy(() => import("../pages/SportsManagment")),
    exact: true,
    access: [
      "view-sports-management",
      "create-update-sports-management",
      "delete-sports-management",
    ],
  },

  {
    path: "/roles/addrole",
    component: lazy(() => import("../pages/AddRole")),
    access: ["create-update-roles-permission"],
  },
  {
    path: "/roles/editrole/:id",
    component: lazy(() => import("../pages/AddRole")),
    access: ["create-update-roles-permission"],
  },
  {
    path: "/roles/viewrole/:id",
    component: lazy(() => import("../pages/ViewRole")),
    access: [
      "view-roles-permissions",
      "create-update-roles-permission",
      "delete-roles-permissions",
    ],
  },
  //parent list

  // parent list add
  {
    path: "/user-management/add",
    component: lazy(() =>
      import("../pages/ParentchildManagement/ParentDetails")
    ),
    access: ["create-update-parent-child"],
  },
  // parent list edit
  {
    path: "/user-management/edit/:id",
    component: lazy(() =>
      import("../pages/ParentchildManagement/ParentDetails")
    ),
    access: ["create-update-parent-child"],
  },
  //child edit page
  {
    path: "/user-management/:id/edit-sub-user/:id",
    component: lazy(() =>
      import("../pages/ParentchildManagement/ChildDetails")
    ),
    access: ["create-update-parent-child"],
  },

  {
    path: `/user-management/:id/view-sub-user/:id`,
    component: lazy(() =>
      import("../pages/ParentchildManagement/ChildDetails")
    ),
    access: [
      "view-parent-child",
      "create-update-parent-child",
      "delete-parent-child",
    ],
  },

  //child add page
  {
    path: "/user-management/:id/add-sub-user",
    component: lazy(() =>
      import("../pages/ParentchildManagement/ChildDetails")
    ),
    access: ["create-update-parent-child"],
  },

  //child detail page
  {
    path: "/user-management/:id/sub-user/:id/details",
    component: lazy(() =>
      import("../pages/ParentchildManagement/ChildAllDetails")
    ),
    access: ["view-parent-child", "create-update-parent-child"],
  },

  {
    path: "/profile",
    component: lazy(() => import("../pages/Profile")),
    exact: true,

    defaultAccess: true,
  },
  {
    path: "/category-mangement",
    component: lazy(() => import("../pages/CategoryManagement")),
    access: ["view-category", "create-update-category", "delete-category"],
    // defaultAccess: true,
  },
  {
    path: "/category-mangement/:id/subcategory",
    component: lazy(() => import("../pages/Subcategory")),
    access: ["view-category", "create-update-category", "delete-category"],
    // defaultAccess: true,
  },

  {
    path: "/school-club-mangement",
    component: lazy(() =>
      import("../pages/SchoolClubManagement/ListofSchoolClub")
    ),
    access: [
      "view-school-club",
      "create-update-school-club",
      "delete-school-club",
    ],
    // defaultAccess: true,
  },
  {
    path: "/school-club-mangement/school-club-details/:id",
    component: lazy(() =>
      import("../pages/SchoolClubManagement/SchoolClubDetail")
    ),
    access: [
      "view-school-club",
      "create-update-school-club",
      "delete-school-club",
    ],
    // defaultAccess: true,
  },
  // {
  //   path: "/school-club-mangement/school-club-details/:id/addplayer",
  //   component: lazy(() => import("../pages/SchoolClubManagement/PlayerAddEditDetails")),
  //   // access: [
  //   //   "view-school-club",
  //   //   "create-update-school-club",
  //   //   "delete-school-club",
  //   // ],
  //   defaultAccess: true
  // },
  // {
  //   path: "/school-club-mangement/:id/editchild/:id",
  //   component: lazy(() =>
  //     import("../pages/ParentchildManagement/ChildDetails")
  //   ),
  //   access: ["create-update-school-club"],
  // },
  {
    path: "/school-club-mangement/:id/viewplayer/:id/:id",
    component: lazy(() => import("../pages/PlayerDetail")),
    access: [
      "view-school-club",
      "create-update-school-club",
      "delete-school-club",
    ],
  },
  {
    path: "/treatment-plan-management",
    component: lazy(() => import("../pages/TreatmentMangementPlan/index.js")),
    access: [
      "view-treatment-plan",
      "create-update-treatment-plan",
      "delete-treatment-plan",
    ],
    // defaultAccess: true,
  },
  {
    path: "/question",
    component: lazy(() => import("../pages/QuestionManagement/QuestionList")),
    // component: lazy(() => import("../pages/ParentchildManagement/Parent")),
    exact: true,
    access: [
      "view-question-management",
      "create-update-question-management",
      "delete-question-management",
    ],
  },
  {
    path: "/question/add",
    component: lazy(() =>
      import("../pages/QuestionManagement/QuestionAddEdit")
    ),
    exact: true,
    access: [
      "view-question-management",
      "create-update-question-management",
      "delete-question-management",
    ],
  },
  {
    path: "/question/edit/:id",
    component: lazy(() =>
      import("../pages/QuestionManagement/QuestionAddEdit")
    ),
    exact: true,
    access: [
      "view-question-management",
      "create-update-question-management",
      "delete-question-management",
    ],
  },
  {
    path: "/news-mangement",
    component: lazy(() => import("../pages/NewsManagement")),
    exact: true,
    access: [
      "view-news-management",
      "create-update-news-management",
      "delete-news-management",
    ],
  },
  {
    path: "/news-mangement/add",
    component: lazy(() => import("../pages/AddNews")),
    exact: true,
    access: ["create-update-news-management"],
  },
  {
    path: "/news-mangement/edit/:id",
    component: lazy(() => import("../pages/AddNews")),
    exact: true,
    access: ["create-update-news-management"],
  },
  {
    path: "/news-mangement/detail/:id",
    component: lazy(() => import("../pages/ViewNews")),
    exact: true,
    access: [
      "view-news-management",
      "create-update-news-management",
      "delete-news-management",
    ],
  },
  // {
  //   path: "/report-mangement",
  //   component: lazy(() => import("../pages/ReportMangement")),
  //   exact: true,
  //   access: ["view-report-management"],
  // },
  // {
  //   path: "/report-mangement/:id",
  //   component: lazy(() => import("../pages/ReportDetail")),
  //   exact: true,
  //   access: ["view-report-management"],
  // },
  // {
  //   path: "/report-mangement/detail/:id",
  //   component: lazy(() => import("../pages/SpecificReport")),
  //   exact: true,
  //   access: ["view-report-management"],
  // },
  {
    path: "/injury-mangement",
    component: lazy(() => import("../pages/InjuryManagement")),
    exact: true,
    access: ["view-injury-management"],
  },
  {
    path: `/injury-mangement/report/:categoryid/:childId/:id`,
    component: lazy(() => import("../pages/InjuryReport")),
    exact: true,
    access: ["view-injury-management"],
  },
  {
    path: `/injury-mangement/detail/:id`,
    component: lazy(() => import("../pages/SpecificReport")),
    exact: true,
    access: ["view-injury-management"],
  },

  {
    path: "/document-mangement",
    component: lazy(() => import("../pages/CmsManagement")),
    exact: true,
    access: ["view-cms-management", "create-update-cms-management"],
  },

  {
    path: "/document-mangement/edit/:id",
    component: lazy(() => import("../pages/CmsManagementEdit")),
    exact: true,
    access: ["create-update-cms-management"],
  },
  {
    path: "/faq-mangement",
    component: lazy(() => import("../pages/FaqManagment")),
    exact: true,
    access: [
      "view-faq-management",
      "create-update-faq-management",
      "delete-faq-management",
    ],
  },
  {
    path: "/foundation-mangement",
    component: lazy(() => import("../pages/Foundation")),
    exact: true,
    access: [
      "view-foundation-management",
      "create-update-foundation-management",
      "delete-foundation-management",
    ],
  },
  {
    path: "/foundation-mangement/add",
    component: lazy(() => import("../pages/AddEditFoundation")),
    exact: true,
    access: [
      "view-foundation-management",
      "create-update-foundation-management",
      "delete-foundation-management",
    ],
  },
  {
    path: "/foundation-mangement/edit/:id",
    component: lazy(() => import("../pages/AddEditFoundation")),
    exact: true,
    access: [
      "view-foundation-management",
      "create-update-foundation-management",
      "delete-foundation-management",
    ],
  },
  {
    path: "/foundation-mangement/detail/:id",
    component: lazy(() => import("../pages/ViewFoundation")),
    exact: true,
    access: [
      "view-foundation-management",
      "create-update-foundation-management",
      "delete-foundation-management",
    ],
  },
  {
    path: "/guest-mangement",
    component: lazy(() => import("../pages/GuestManagment")),
    exact: true,
    access: ["view-guest-management"],
    
  },
  {
    path: "/guest-mangement/:id",
    component: lazy(() => import("../pages/GuestReport")),
    exact: true,
    access: ["view-guest-management"],
    
  },
  {
    path: `/guest-mangement/:id/details/:id`,
    component: lazy(() => import("../pages/SpecificReportForGuest")),
    exact: true,
    access: ["view-guest-management"],
    
  },

  //class team for admin
  {
    path: "/school-club-mangement/school-club-details/:id/add",
    component: lazy(() => import("../pages/AddEditClassTeam")),
    exact: true,
    access: ["create-update-school-club"],
    // defaultAccess: true,
  },
  {
    path: "/school-club-mangement/school-club-details/:id/edit/:id",
    component: lazy(() => import("../pages/AddEditClassTeam")),
    exact: true,
    access: ["create-update-school-club"],
   
  },
  {
    path: "/school-club-mangement/school-club-details/:id/playelist/:id",
    component: lazy(() => import("../pages/TeamClassPlayerList")),
    exact: true,
    access: [
      "view-school-club",
      "create-update-school-club",
      "delete-school-club",
    ],
    
  },

  // school/Club login

  {
    path: "/member-management",
    component: lazy(() => import("../pages/SchoolClubUserMangment")),
    exact: true,
    access: ["delete-member", "view-member", "create-update-member"],
    // defaultAccess: true,
  },
  {
    path: "/member-management/:id",
    component: lazy(() => import("../pages/SchoolMemberDetail")),
    exact: true,
    access: ["delete-member", "view-member", "create-update-member"],
    // defaultAccess: true,
  },

  {
    path: "/class-team-managment",
    component: lazy(() => import("../pages/SchoolClubClassList")),
    exact: true,
    access: [
      "view-class-List",
      "create-update-class-list",
      "delete-class-list",
    ],
    // defaultAccess: true,
  },
  {
    path: "/class-team-managment/add",
    component: lazy(() => import("../pages/AddEditClassTeamForSchool")),
    exact: true,
    access: ["create-update-class-list"],
    // defaultAccess: true,
  },
  {
    path: "/class-team-managment/edit/:id",
    component: lazy(() => import("../pages/AddEditClassTeamForSchool")),
    exact: true,
    access: ["create-update-class-list"],
    // defaultAccess: true,
  },
  {
    path: "/class-team-managment/player-list/:id",
    component: lazy(() => import("../pages/TeamClassPlayerListForSchool")),
    exact: true,
    access: [
      "view-class-List",
      "create-update-class-list",
      "delete-class-list",
    ],
    // defaultAccess: true,
  },

  // {
  //   path: "/notification",
  //   component: lazy(() => import("../pages/Notification/NotificationList")),
  //   exact: true,
  //   access: [
  //     "view-notification-management",
  //     "create-notification-management",
  //     "delete-notification-management",
  //   ],
  //   defaultAccess: true,
  // },
  // {
  //   path: "/notification",
  //   component: lazy(() => import("../pages/Notification/NotificationAdd")),
  //   exact: true,
  //   access: [
  //     "view-faq-management",
  //     "create-update-faq-management",
  //     "delete-faq-management",
  //   ],
  //   defaultAccess: true,
  // },
 
];

export { PublicRoutes, PrivateRoutes };
