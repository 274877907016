import { createSlice } from "@reduxjs/toolkit";
import {
  createInjuryAction,
  editInjuryAction,
  getAllInjuryAction,
  deleteInjuryAction,
} from "./actions";
import { notification } from "antd";

const initialState = {
  createdInjury: {},
  loadingCreateInjury: false,
  errorCreateInjury: null,

  editedInjury: {},
  loadingEditInjury: false,
  errorEditInjury: null,

  allInjury: [],
  loadingAllInjury: false,
  errorAllInjury: null,

  deletedInjury: {},
  loadingDeleteInjury: false,
  errorDeleteInjury: null,
};
const injurySlice = createSlice({
  name: "injury",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getAllInjuryAction.pending, (state) => {
        state.loadingAllInjury = true;
        state.errorAllInjury = null;
      })
      .addCase(getAllInjuryAction.fulfilled, (state, action) => {
        state.loadingAllInjury = false;
        state.allInjury = action.payload;
      })
      .addCase(getAllInjuryAction.rejected, (state, action) => {
        state.loadingAllInjury = false;
        state.errorAllInjury = action.payload;
      });
  },
});
export default injurySlice.reducer;
