import { get, post, del, put } from "../../helpers/apiWrapper";


export const createInjury = async (treatmentData) => {
  const config = {
    data: treatmentData,
  };
  return await post(`/treatment-category/create`, config);
};

export const editInjury = async ({ formData, data }) => {
  const config = {
    data: formData,
  };
  return await put(`/treatment-category/${data?.id}`, config);
};

export const getAllInjury = async (data) => {
  const config = {
    params: data,
  };
  return await post(`/Logs/getAllChildWithInjury`, config);
};

export const deleteInjury = async (data) => {
  return await del(`/treatment-category/${data?.id}`);
};



