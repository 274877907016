import { createAsyncThunk } from "@reduxjs/toolkit";
import { createNews, editNews, getAllNews,deleteNews,getSingleNews } from "../../api/newsManagement";

// Thunk action for get all user
export const createNewsAction = createAsyncThunk(
  "news/create",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await createNews(userData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk action for edit user
export const editNewsAction = createAsyncThunk(
  "news/edit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await editNews(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk action for get all user
export const getAllNewsAction = createAsyncThunk(
  "news/getAll",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllNews(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getSingleNewsAction = createAsyncThunk(
  "news/single",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getSingleNews(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Thunk action for delelte employee
export const deleteNewsAction = createAsyncThunk(
  "news/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deleteNews(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);