import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllPlayer,createPlayer,deletePlayer,editPlayer } from "../../api/playerlist";
export const getAllPlayerAction = createAsyncThunk(
  "allPlayer/get",
  async (childData, { rejectWithValue }) => {
    try {
      const response = await getAllPlayer(childData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createPlayerAction = createAsyncThunk(
  "player/create",
  async (childData, { rejectWithValue }) => {
    try {
      const response = await createPlayer(childData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deletePlayerAction = createAsyncThunk(
  "player/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deletePlayer(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const editPlayerAction = createAsyncThunk(
  "player/edit",
  async (childData, { rejectWithValue }) => {
    try {
      const response = await editPlayer(childData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
