import { createSlice } from "@reduxjs/toolkit";
import {
  getAllSchoolClubUserAction,
  schoolOrClubMemberDetailAction,
  removeMemberAction
} from "./actions";
import { notification } from "antd";

const initialState = {
  memberDetails: {},
  loadingDetails: false,
  errorDetails: null,

  allUserForSchoolClub: {},
  loadingAll: false,
  errorAll: null,

  deletedMember: {},
  loadingDelete: false,
  errorDelete: null,
};
const schoolClubUserSlice = createSlice({
  name: "schoolClubUser",
  initialState,
  reducers: {
    clearDetails: (state) => {
      state.memberDetails = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(schoolOrClubMemberDetailAction.pending, (state) => {
        state.loadingDetails = true;
        state.errorDetails = null;
      })
      .addCase(schoolOrClubMemberDetailAction.fulfilled, (state, action) => {
        state.loadingDetails = false;
        state.memberDetails = action.payload;
      })
      .addCase(schoolOrClubMemberDetailAction.rejected, (state, action) => {
        state.loadingDetails = false;
        state.errorDetails = action.payload;
      })

      .addCase(getAllSchoolClubUserAction.pending, (state) => {
        state.loadingAll = true;
        state.errorAll = null;
      })
      .addCase(getAllSchoolClubUserAction.fulfilled, (state, action) => {
        console.log(action?.payload, "payload");
        state.loadingAll = false;
        state.allUserForSchoolClub = action.payload;
      })
      .addCase(getAllSchoolClubUserAction.rejected, (state, action) => {
        state.loadingAll = false;
        state.errorAll = action.payload;
      })
    .addCase(removeMemberAction.pending, (state) => {
      state.loadingDelete = true;
      state.errorDelete = null;
    })
    .addCase(removeMemberAction.fulfilled, (state, action) => {
      console.log(action?.payload);
      state.loadingDelete = false;
      state.deletedMember = action.payload;
      state.allUserForSchoolClub.children = state.allUserForSchoolClub.children.filter(
        (groupId) => groupId?.id !== action?.payload?.data?.id
      );
      state.allUserForSchoolClub.meta.pagination.totalChildren -= 1;
      if (action?.payload?.meta?.success === true) {
        notification.success({
          message: "Success",
          description: action?.payload?.meta?.message,
          duration: 2,
        });
      } else {
        notification.error({
          message: "Error",
          description: action?.payload?.meta?.message,
          duration: 2,
        });
      }
    })
    .addCase(removeMemberAction.rejected, (state, action) => {
      state.loadingDelete = false;
      state.errorDelete = action.payload;
    });
  },
});
export default schoolClubUserSlice.reducer;
export const { clearDetails } = schoolClubUserSlice.actions;
