import { get, post, del, put } from "../../helpers/apiWrapper";

export const createFAQ = async (treatmentData) => {
  const config = {
    data: treatmentData,
  };
  return await post(`/faq/create`, config);
};

export const editFAQ = async ({ formData, data }) => {
  const config = {
    data: formData,
  };
  return await put(`/faq/edit/${data?.id}`, config);
};

export const getAllFAQ = async () => {
  return await get(`/faq/all`);
};

export const deleteFAQ = async (data) => {
  return await del(`/faq/delete/${data?.id}`);
};
