import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllChildForReport,getsInitialReport,getDownlolReport,getTimesofReport } from "../../api/reportManagement";

// Thunk action for get all user
export const getAllChildForReportAction = createAsyncThunk(
  "childForReport/getAll",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllChildForReport(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getAllTimesofReportAction = createAsyncThunk(
  "get/allTimesofReport",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getTimesofReport(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getInitialReportAction = createAsyncThunk(
  "get/initialReport",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getsInitialReport(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDownloadReportAction = createAsyncThunk(
  "get/downloadReport",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getDownlolReport(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// // Thunk action for delelte employee
// export const deleteNewsAction = createAsyncThunk(
//   "news/delete",
//   async (data, { rejectWithValue }) => {
//     try {
//       const response = await deleteNews(data);
//       return response;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );