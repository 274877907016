import { createSlice } from "@reduxjs/toolkit";
import { fetchUser, updateUserAction, fetchVersion } from "./actions";
import { notification } from "antd";

const initialState = {
  userDetail: {},
  loading: false,
  error: null,

  updatedDetail: {},
  loadingUpdate: false,
  errorUpdate: null,

  permissions: [],

  version: {},
  loadingVersion: false,
  errorVersion: null,
};
const getUserSlice = createSlice({
  name: "getUser",
  initialState,
  reducers: {
    clearuserDetailData: (state) => {
      state.userDetail = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        console.log(action, "action");
        state.loading = false;
        state.userDetail = action.payload.data;
        if (action?.payload?.data?.role?.permissions) {
          state.permissions = action?.payload?.data?.role?.permissions.map(
            (permission) => permission.slug
          );
        }
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateUserAction.pending, (state, action) => {
        state.loadingUpdate = true;
        state.errorUpdate = null;
      })
      .addCase(updateUserAction.fulfilled, (state, action) => {
        state.loadingUpdate = false;
        state.updatedDetail = action.payload.data;
        state.userDetail = action.payload.data;
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(updateUserAction.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.payload;
      })

      .addCase(fetchVersion.pending, (state) => {
        state.loadingVersion = true;
        state.errorVersion = null;
      })
      .addCase(fetchVersion.fulfilled, (state, action) => {
        state.loadingVersion = false;
        state.version = action.payload;
      })
      .addCase(fetchVersion.rejected, (state, action) => {
        state.loadingVersion = false;
        state.errorVersion = action.payload;
      });
  },
});

export const { clearuserDetailData } = getUserSlice.actions;
export default getUserSlice.reducer;
