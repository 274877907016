import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSubCategory, editSubCategory, getSubcategory, deleteSubCategory} from "../../api/categorymanegment";

// Thunk action for get all user
export const createSubCategoryAction = createAsyncThunk(
  "subCategory/create",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await createSubCategory(userData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk action for edit user
export const editSubCategoryAction = createAsyncThunk(
  "subcategory/edit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await editSubCategory(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk action for get all user
export const getAllSubcategoryAction = createAsyncThunk(
  "subcategory/getAll",
  async (subData, { rejectWithValue }) => {
    try {
      const response = await getSubcategory(subData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Thunk action for delelte employee
export const deleteSubCategoryAction = createAsyncThunk(
  "subcategory/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deleteSubCategory(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);