import React, { useEffect, useState } from "react";
import "./SideBar.scss";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import headerLogo from "../../../Assets/headerlogo.svg";
import Group from "../../../Assets/Group.svg";

import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { Layout, Menu, Tooltip, theme } from "antd";
import { toggleSidebar } from "../../../reducers/SidebarCollapse";
import { useNavigate } from "react-router-dom";
import { items } from "../../../constants";
import { clearPostCSVData, clearschoolClubDetail } from "../../../services/store/SchoolClubMangement/slice";
import { clearParentData } from "../../../services/store/Parent&Child/slice";
import { clearAllSubCategoryDetail } from "../../../services/store/Subcategory/slice";
import { clearDetails } from "../../../services/store/SchoolClubUser/slice";
import { clearPostCSVDataForTeam } from "../../../services/store/ClassTeamManagement/slice";

const { Sider } = Layout;
const { SubMenu } = Menu;

const SideBar = ({ selectedImage, showImage }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [activeKey, setActiveKey] = useState(null);
  console.log(data, "data");
  const onClickLogo = () => {
    navigate("/");
  };

  const {
    token: { colorPrimary },
  } = theme.useToken();
  console.log(colorPrimary);

  const { isCollapse } = useSelector((state) => state.sidebarCollapse);
  const { userDetail } = useSelector((state) => state.getUser);

  useEffect(() => {
    const activeItem = findActiveItem(data, location.pathname);
    setActiveKey(activeItem?.key);
  }, [data, location]);

  const findActiveItem = (items, pathname) => {
    for (const item of items) {
      if (isActive(item.route, pathname)) {
        return item;
      }
      if (item.isSubmenu && item.SubMenu) {
        const activeSubItem = findActiveItem(item.SubMenu, pathname);
        if (activeSubItem) {
          return activeSubItem;
        }
      }
    }
    return null;
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("data"));
    const permissions = userData?.role?.permissions;
    const slugs = permissions?.map((permission) => permission.slug);

    let filteredItems = items
      ?.map((item) => {
        if (item?.isSubmenu && item?.SubMenu) {
          const subMenu = item?.SubMenu?.filter((subItem) =>
            subItem?.access?.some((access) => slugs?.includes(access))
          );
          return {
            ...item,
            SubMenu: subMenu.length > 0 ? subMenu : null, // Remove submenu if no valid access
          };
        } else {
          if (item?.access?.some((access) => slugs?.includes(access))) {
            return item;
          }
          return null;
        }
      })
      .filter((item) => {
        if (item?.isSubmenu && !item?.SubMenu) {
          return false; // Remove main item if submenu is removed
        }
        return item !== null;
      });

    filteredItems?.unshift(items[0])

    setData(filteredItems);
  }, [localStorage.getItem("data"), userDetail]);
  const isActive = (path) => {
    const currentPathname = location.pathname;
    return currentPathname === path || currentPathname.startsWith(path + "/");
  };

  const handleClear = () => {
    dispatch(clearschoolClubDetail());
    dispatch(clearParentData());
    dispatch(clearAllSubCategoryDetail());
    dispatch(clearDetails())
    dispatch(clearPostCSVData());
    dispatch(clearPostCSVDataForTeam());
  };
  return (
    <>
      <Sider
        trigger={null}
        collapsible
        width={250}
        collapsed={isCollapse}
        className={` h-[100vh] main-side-bar `}
      >
        <div
          className={
            isCollapse ? `logoBoxContainerOneByOne` : `logoBoxContainer `
          }
          onClick={onClickLogo}
          data-testid="sidebar-top-logo"
        >
          <div className="logoBox">
            <img
              src={isCollapse ? Group : headerLogo}
              alt="logo"
              data-testid="image-logo-id"
              onClick={() => navigate("/")}
            />
          </div>
        </div>
        <div className="sideBarMenuList pb-6" data-testid="main-sideBarMenu-id">
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[activeKey]}
            defaultOpenKeys={[data?.find((item) => isActive(item.route))?.key]}
            // items={items}
            className="pb-4 menuuuu"
          >
            {data?.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  {item?.isSubmenu === false ? (
                    <Menu.Item
                      key={item?.key}
                      className={isActive(item?.route) ? "active" : ""}
                    >
                      <NavLink to={item?.route} onClick={handleClear}>
                        {item?.icon}
                        <span>{item?.label}</span>
                      </NavLink>
                    </Menu.Item>
                  ) : null}
                  {item?.isSubmenu ? (
                    <SubMenu
                      title={
                        <>
                          {item?.icon}
                          <span className="">{item?.label}</span>
                        </>
                      }
                      className="siderSubMenu"
                    >
                      {item?.SubMenu?.map((subItem) => {
                        return (
                          <Menu.Item key={subItem?.key}>
                            <NavLink to={subItem?.route}>
                            {subItem?.icon}
                              <span>{subItem?.label}</span>
                            </NavLink>
                          </Menu.Item>
                        );
                      })}
                    </SubMenu>
                  ) : null}
                </React.Fragment>
              );
            })}
          </Menu>
        </div>
        <div
          className="sider-toggler-main cursor-pointer bg-primary"
          data-testid="main-collapse-div-id"
          style={{ backgroundColor: `${colorPrimary}` }}
          onClick={() => dispatch(toggleSidebar(!isCollapse))}
        >
          {React.createElement(isCollapse ? RightOutlined : LeftOutlined, {
            className: "trigger",

            style: { fontSize: "16px", color: "#fff" },
          })}
        </div>
      </Sider>
    </>
  );
};

export default SideBar;