import { get, post, del, put } from "../../helpers/apiWrapper";


export const getAllGuest = async (data) => {
  const config = {
    params: data,
  };
  return await get(`/guest`, config);
};

export const getLogHistoryForGuest = async (data) => {
  return await get(`/guest/log/${data?.id}`);
};

export const getFullReportForGuest = async (data) => {
  return await post(`/guest/get-result/${data?.id}`);
};