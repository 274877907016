import { get, post, put, del } from "../../helpers/apiWrapper";

// post api for create category
export const createCategory = async (childData) => {
  const config = {
    data: childData,
  };
  return await post(`/category/create`, config);
};

// patch API for Edit category
export const editCategory = async ({ editedData, data }) => {
  const config = {
    data: editedData,
  };
  return await put(`/category/${data?.id}`, config);
};

// get API for get all user
export const getAllCategory = async (data) => {
  const config = {
    params: data,
  };

  return await get(`/category/all`, config);
};

export const deleteCategory = async (data) => {
  return await del(`/category/${data?.id}`);
};

// Subcategory

export const getSubcategory = async ({ data, dataId }) => {
  const config = {
    params: data,
  };

  return await get(`/category/${dataId?.id}`, config);
};


export const createSubCategory = async (childData) => {
  const config = {
    data: childData,
  };
  return await post(`/category/create`, config);
};


export const editSubCategory = async ({ editedData, data }) => {
  const config = {
    data: editedData,
  };
  return await put(`/category/${data?.id}`, config);
};

export const deleteSubCategory = async (data) => {
  return await del(`/category/${data?.id}`);
};
