import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllParent,
  createParent,
  createChild,
  deleteParent,
  getParentById,
  editParent,
  createParentByCSV,
  getAllGuardian
} from "../../api/parent&child";

export const fetchParent = createAsyncThunk(
  "get/parent",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllParent(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const createParentAction = createAsyncThunk(
  "parent/create",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await createParent(userData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const createChildAction = createAsyncThunk(
  "child/create",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await createParent(userData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteParentAction = createAsyncThunk(
  "parent/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deleteParent(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserByIdAction = createAsyncThunk(
  "parent/byId",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getParentById(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const editParentAction = createAsyncThunk(
  "parent/edit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await editParent(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const createParentByCSVAction = createAsyncThunk(
  "parent/createByCSV",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await createParentByCSV(userData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchGuardian = createAsyncThunk(
  "get/guardian",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllGuardian(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteGuardianAction = createAsyncThunk(
  "guardian/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deleteParent(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
