import { configureStore } from "@reduxjs/toolkit";

import sidebarCollapseReducer from "../../reducers/SidebarCollapse";
import darkModeReducer from "../../reducers/DarkMode";
import RtlModeReducer from "../../reducers/RtlMode";
import authenticationReducer from "../store/Authentication/slice";
import employeeReducer from "../store/Employee/slice";
import getUserReducer from "../store/GetUser/slice";
import parentReducer from "../store/Parent&Child/slice";
import rolesReducer from "../store/Roles&Permissions/slice";
import sportsReducer from "../store/Sports/slice";
import childReducer from "../store/child/slice";
import categoryReducer from "../store/Category/slice";
import subcategoryReducer from "../store/Subcategory/slice";
import schoolClubManagementReducer from "../store/SchoolClubMangement/slice";
import playerReducer from "../store/PlayerList/slice";
import treatmentPlanReducer from "../store/TreatmentPlanMangement/slice";
import questionReducer from "../store/Questions/slice";
import newsReducer from "../store/NewsManagement/slice";
import reportReducer from "../store/ReportManagement/slice"
import injuryReducer from "../store/InjuryManagement/slice"
import cmsReducer from  "../store/CMSManagement/slice"
import faqReducer from "../store/FAQMangement/slice"
import foundationReducer from "../store/Foundation/slice"
import schoolClubUserReducer from "../store/SchoolClubUser/slice"
import classManagmentReducer from "../store/ClassTeamManagement/slice"
import guestReducer from "../store/GuestManagement/slice"
import notificationReducer from "../store/Notification/slice";
import dashboardReducer from "../store/Dashboard/slice"


export default configureStore({
  reducer: {
    sidebarCollapse: sidebarCollapseReducer,
    darkMode: darkModeReducer,
    rtlMode: RtlModeReducer,
    authentication: authenticationReducer,
    employee: employeeReducer,
    getUser: getUserReducer,
    parent: parentReducer,
    roles: rolesReducer,
    sports: sportsReducer,
    child: childReducer,
    category: categoryReducer,
    subCategory: subcategoryReducer,
    SchoolClubManagement: schoolClubManagementReducer,
    player: playerReducer,
    treatmentplan: treatmentPlanReducer,
    question: questionReducer,
    news: newsReducer,
    report:reportReducer,
    injury: injuryReducer,
    cms: cmsReducer,
    faq: faqReducer,
    foundation:foundationReducer,
    schoolClubUser: schoolClubUserReducer,
    class: classManagmentReducer,
    guest: guestReducer,
    notification: notificationReducer,
    dashboard: dashboardReducer
  },
});
