import { createSlice } from "@reduxjs/toolkit";
import { getAllGuestAction, getLogHistoryForGuestAction,getFullReportForGuestAction } from "./actions";
import { notification } from "antd";

const initialState = {
  allGuest: [],
  loadingAllGuest: false,
  errorAllGuest: null,

  logHistoryGuest: {},
  loadinglogHistoryGuest: false,
  errorlogHistoryGuest: null,

  fullReportForGuest: {},
  loadingFullReportForGuest: false,
  errorFullReportForGuest: null,
};
const guestSlice = createSlice({
  name: "guest",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getAllGuestAction.pending, (state) => {
        state.loadingAllGuest = true;
        state.errorAllGuest = null;
      })
      .addCase(getAllGuestAction.fulfilled, (state, action) => {
        state.loadingAllGuest = false;
        state.allGuest = action.payload;
      })
      .addCase(getAllGuestAction.rejected, (state, action) => {
        state.loadingAllGuest = false;
        state.errorAllGuest = action.payload;
      })
      .addCase(getLogHistoryForGuestAction.pending, (state) => {
        state.loadinglogHistoryGuest = true;
        state.errorlogHistoryGuest = null;
      })
      .addCase(getLogHistoryForGuestAction.fulfilled, (state, action) => {
        state.loadinglogHistoryGuest = false;
        state.logHistoryGuest = action.payload;
      })
      .addCase(getLogHistoryForGuestAction.rejected, (state, action) => {
        state.loadinglogHistoryGuest = false;
        state.errorlogHistoryGuest = action.payload;
      })
      .addCase(getFullReportForGuestAction.pending, (state) => {
        state.loadingFullReportForGuest = true;
        state.errorFullReportForGuest = null;
      })
      .addCase(getFullReportForGuestAction.fulfilled, (state, action) => {
        state.loadingFullReportForGuest = false;
        state.fullReportForGuest = action.payload;
      })
      .addCase(getFullReportForGuestAction.rejected, (state, action) => {
        state.loadingFullReportForGuest = false;
        state.errorFullReportForGuest = action.payload;
      })
  },
});
export default guestSlice.reducer;
