import { createSlice } from "@reduxjs/toolkit";
import {
  editCmsAction,
  getAllCMSAction,
  getSingleCMSAction,
  getSingleCMSBySlugAction,
} from "./actions";
import { notification } from "antd";

const initialState = {
  editedCMS: {},
  loadingEditCMS: false,
  errorEditCMS: null,

  allCMS: [],
  loadingAllCMS: false,
  errorAllCMS: null,

  singleCMS: {},
  loadingSingleCMS: false,
  errorSingleCMS: null,

  singleCMSBySlug: {},
  loadingSingleCMSBySlug: false,
  errorSingleCMSBySlug: null,
};
const cmsSlice = createSlice({
  name: "cms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(editCmsAction.pending, (state) => {
        state.loadingEditCMS = true;
        state.errorEditCMS = null;
      })
      .addCase(editCmsAction.fulfilled, (state, action) => {
        state.loadingEditCMS = false;
        state.errorEditCMS = action.payload;
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(editCmsAction.rejected, (state, action) => {
        state.loadingEditCMS = false;
        state.errorEditCMS = action.payload;
      })
      .addCase(getAllCMSAction.pending, (state) => {
        state.loadingAllCMS = true;
        state.errorAllCMS = null;
      })
      .addCase(getAllCMSAction.fulfilled, (state, action) => {
        state.loadingAllCMS = false;
        state.allCMS = action.payload;
      })
      .addCase(getAllCMSAction.rejected, (state, action) => {
        state.loadingAllCMS = false;
        state.errorAllCMS = action.payload;
      })
      .addCase(getSingleCMSAction.pending, (state) => {
        state.loadingSingleCMS = true;
        state.errorSingleCMS = null;
      })
      .addCase(getSingleCMSAction.fulfilled, (state, action) => {
        state.loadingSingleCMS = false;
        state.singleCMS = action.payload;
      })
      .addCase(getSingleCMSAction.rejected, (state, action) => {
        state.loadingSingleCMS = false;
        state.errorSingleCMS = action.payload;
      })
      .addCase(getSingleCMSBySlugAction.pending, (state) => {
        state.loadingSingleCMSBySlug = true;
        state.errorSingleCMSBySlug = null;
      })
      .addCase(getSingleCMSBySlugAction.fulfilled, (state, action) => {
        state.loadingSingleCMSBySlug = false;
        state.singleCMSBySlug = action.payload;
      })
      .addCase(getSingleCMSBySlugAction.rejected, (state, action) => {
        state.loadingSingleCMSBySlug = false;
        state.errorSingleCMSBySlug = action.payload;
      });
  },
});
export default cmsSlice.reducer;
