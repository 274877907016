import React, { Suspense } from "react";
import "./App.scss";
import { Routes, Route, Navigate } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { PrivateRoutes, PublicRoutes } from "./routes";
import NonAuth from "./components/NonAuthLayout";
import AuthLayout from "./components/AuthLayout";
import config from "./utils/url-config";
function App() {
  let location = useLocation();
  let pathname = location?.pathname?.split("/")[1];
  let title = pathname
    ? `Baseline_${pathname.charAt(0).toUpperCase() + pathname.slice(1)}`
    : "";
  const userData = JSON.parse(localStorage?.getItem("data"));
  
  const titleMap = {
    "": "Dashboard",
    "login": "Baseline-login",
    "forgotPassword": "Forgot Password",
    "roles": "Roles & Permissions",
    "user-management": "User Management",
    "sports": "Sports Management",
    "employee": "Admin User Management",
    "addrole": "Roles & Permissions",
    "category-mangement": "Category Management",
    "school-club-mangement": "School Club Management",
    "treatment-plan-management": "Treatment Plan Management",
    "question": "Question Management",
    "profile": "Profile",
    "injury-mangement": "Injury Management",
    "report-mangement": "Report Management",
    "news-mangement": "News Management",
    "document-mangement": "Document Management",
    "faq-mangement": "FAQ Management",
    "foundation-mangement": "Foundation Management",
    "member-management": "Member Management",
    "guest-mangement": "Guest Management",
    "notification": "Notification",
  };
  // To set the page title based on the url
  useEffect(() => {
    const pathname = location.pathname.split("/")[1];
    let title = titleMap[pathname] || `Baseline`;

    if (pathname === "class-team-managment") {
      title = userData?.user_type === "school" ? "Class Management" : "Team Management";
    }

    document.title = title;
  }, [location, userData]);
  

  const token = localStorage.getItem("BASELINE_TOKEN");
  const isLoggedIn = token ? true : false;

 

  let routes = [];
  if (userData) {
    const permissions = userData?.role?.permissions;
    const slugs = permissions?.map((permission) => permission.slug);
    routes = PrivateRoutes?.filter((item) => {
      if (item?.defaultAccess) {
        return true;
      }
      return item?.access?.some((element) => slugs?.includes(element));
    });
  }

  return (
    <Routes>
      {PublicRoutes.map(({ path, exact, component: Component }) => (
        <Route
          key={path}
          path={path}
          exact={exact}
          element={
            <Suspense fallback="">
              <NonAuth>
                <Component />
              </NonAuth>
            </Suspense>
          }
        />
      ))}
      <Route path="/" exact element={<AuthLayout />}>
        {routes?.map(({ path, exact, component: Component, access }) => {
          return isLoggedIn ? (
            <Route
              key={path}
              path={path}
              exact={exact}
              element={<Component />}
            />
          ) : (
            <Route
              key={path}
              path={path}
              exact={exact}
              element={<Navigate to="/login" />}
            />
          );
        })}
      </Route>
    </Routes>
  );
}

export default App;
