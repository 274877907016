import { get, post, put, del } from "../../helpers/apiWrapper";

// POST API for createParent
export const createParent = async (userData) => {
  const config = {
    data: userData,
  };
  return await post(`/user/create-user-profile`, config);
};

export const createChild = async (childData) => {
  const config = {
    data: childData,
  };
  return await post(`/child/create`, config);
};

// patch API for Edit USer
export const editParent = async ({ formData, data }) => {
  const config = {
    data: formData,
  };
  // return await put(`/user/edit/${data?.id}`, config);
  return await put(`/user/${data?.id}/edit`, config);
};

// get API for get all user
export const getAllParent = async (data) => {
  const config = {
    params: data,
  };
  return await get(`/user/all`, config);
};

// delete Employee
export const deleteParent = async (data) => {
  const config = {
    data: data,
  };
  return await del(`/user/delete/${data?.id}`);
};

export const getParentById = async (data) => {
  const config = {
    data: data,
  };
  return await get(`/user/${config?.data}`);
};

export const createParentByCSV = async (fileData) => {
  const config = {
    data: fileData,
  };
  return await post(`/user/upload-csv`, config);
};

export const getAllGuardian = async ({data,userId}) => {
  const config = {
    params: data,
  };
  return await get(`/user/get-guardian/${userId?.id}`, config);
};
