import { get, post, put, del } from "../../helpers/apiWrapper";

// POST API for createUser
export const createNews = async (userData) => {
  const config = {
    data: userData,
  };
  return await post(`/blog/create`, config);
};

// patch API for Edit USer
export const editNews = async ({ formData, data }) => {
  const config = {
    data: formData,
  };
  return await put(`/blog/edit/${data?.id}`, config);
};

// get API for get all user
export const getAllNews = async (data) => {
  const config = {
    params: data,
  };
  return await get(`/blog/all-blogs`, config);
};
export const getSingleNews = async (data) => {
  return await get(`/blog/${data?.id}`);
};

// delete Employee
export const deleteNews = async (data) => {
  return await del(`/blog/delete/${data?.id}`);
};
