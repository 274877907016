import { createAsyncThunk } from "@reduxjs/toolkit";
import { createFAQ, editFAQ, getAllFAQ,deleteFAQ } from "../../api/faqManagment";

// Thunk action for create 
export const createFAQAction = createAsyncThunk(
  "FAQ/create",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await createFAQ(userData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk action for edit treatment plan
export const editFAQAction = createAsyncThunk(
  "FAQ/edit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await editFAQ(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk action for get all treatment plan
export const getAllFAQAction = createAsyncThunk(
  "FAQ/getAll",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllFAQ(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Thunk action for delete treatment plan
export const deleteFAQAction = createAsyncThunk(
  "FAQ/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deleteFAQ(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);