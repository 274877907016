import { createSlice } from "@reduxjs/toolkit";
import {
  createSportAction,
  editSportAction,
  getAllSportsAction,
  deleteSportAction,
  fetchSportAction,
  getSportsForChildAction,
} from "./actions";
import { notification } from "antd";

const initialState = {
  createdSports: {},
  loadingCreate: false,
  errorCreate: null,

  editedSports: {},
  loadingEdit: false,
  errorEdit: null,

  deletedSports: {},
  loadingDelete: false,
  errorDelete: null,

  allSports: [],
  loadingAll: false,
  errorAll: null,

  allSportsForChild: {},
  loadingAllSportsForChild: false,
  errorForChild: null,

  getSportsList: {},
  loadingGetSportsList: false,
  errorGetSportsList: null,
};
const sportsSlice = createSlice({
  name: "sports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSportAction.pending, (state) => {
        state.loadingCreate = true;
        state.errorCreate = null;
      })
      .addCase(createSportAction.fulfilled, (state, action) => {
        state.loadingCreate = false;
        state.createdSports = action.payload;
        if (action?.payload?.meta?.success === true) {
          if (state.allSports?.meta?.pagination?.currentPage == 1) {
            state.allSports.data = [
              action.payload?.data,
              ...state?.allSports?.data,
            ];
          }

          // state?.allSports?.data?.push(action.payload?.data);
          state.allSports.meta.pagination.totalItems += 1;
        }
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(createSportAction.rejected, (state, action) => {
        state.loadingCreate = false;
        state.errorCreate = action.payload;
      })
      .addCase(editSportAction.pending, (state) => {
        state.loadingEdit = true;
        state.errorEdit = null;
      })
      .addCase(editSportAction.fulfilled, (state, action) => {
        state.loadingEdit = false;
        state.editedUser = action.payload;
        state.allSports.data = state?.allSports?.data.map((item) =>
          item.id === action.payload?.data?.id ? action.payload?.data : item
        );
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(editSportAction.rejected, (state, action) => {
        state.loadingEdit = false;
        state.errorEdit = action.payload;
      })
      .addCase(deleteSportAction.pending, (state) => {
        state.loadingDelete = true;
        state.errorDelete = null;
      })
      .addCase(deleteSportAction.fulfilled, (state, action) => {
        state.loadingDelete = false;
        state.deletedSports = action.payload;
        state.allSports.data = state?.allSports?.data?.filter(
          (groupId) => groupId?.id !== action?.payload?.data?.id
        );
        state.allSports.meta.pagination.totalItems -= 1;
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(deleteSportAction.rejected, (state, action) => {
        state.loadingDelete = false;
        state.errorDelete = action.payload;
      })
      .addCase(fetchSportAction.pending, (state) => {
        state.loadingAll = true;
        state.errorAll = null;
      })
      .addCase(fetchSportAction.fulfilled, (state, action) => {
        state.loadingAll = false;
        state.allSports = action.payload;
      })
      .addCase(fetchSportAction.rejected, (state, action) => {
        state.loadingAll = false;
        state.errorAll = null;
      })
      .addCase(getAllSportsAction.pending, (state) => {
        state.loadingAllSportsForChild = true;
        state.errorForChild = null;
      })
      .addCase(getAllSportsAction.fulfilled, (state, action) => {
        state.loadingAllSportsForChild = false;
        state.allSportsForChild = action.payload;
      })
      .addCase(getAllSportsAction.rejected, (state, action) => {
        state.loadingAllSportsForChild = false;
        state.errorForChild = null;
      })
      .addCase(getSportsForChildAction.pending, (state) => {
        state.loadingGetSportsList = true;
        state.errorGetSportsList = null;
      })
      .addCase(getSportsForChildAction.fulfilled, (state, action) => {
        state.loadingGetSportsList = false;
        state.getSportsList = action.payload;
      })
      .addCase(getSportsForChildAction.rejected, (state, action) => {
        state.loadingGetSportsList = false;
        state.errorGetSportsList = null;
      });
  },
});
export default sportsSlice.reducer;
