import { createSlice } from "@reduxjs/toolkit";
import {
  fetchParent,
  createParentAction,
  deleteParentAction,
  getUserByIdAction,
  editParentAction,
  createParentByCSVAction,
  fetchGuardian,
  deleteGuardianAction
} from "./actions";
import { notification } from "antd";

const initialState = {
  createParent: {},
  loadingCreate: false,
  errorCreate: null,

  editedParent: {},
  loadingEdit: false,
  errorEdit: null,

  getAllParent: [],
  loadingGetAllParent: false,
  errorGetAllParent: null,

  getByParentId: {},
  loadingGetByParentId: false,
  errorGetByParentId: null,

  deletedParent: {},
  loadingDelete: false,
  errorDelete: null,

  createParentByCSV: {},
  errorParentByCSV: {},
  loadingCreateParentByCSV: false,
  errorCreateParentByCSV: null,

  getAllGuardian: [],
  loadingGetAllGuardian: false,
  errorGetAllGuardian: null,

  deletedGuardian: {},
  loadingDeleteGuardian: false,
  errorDeleteGuardian: null,

};
const parentSlice = createSlice({
  name: "parent",
  initialState,
  reducers: {
    clearParentData: (state) => {
      state.getByParentId = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchParent.pending, (state) => {
        state.loadingGetAllParent = true;
        state.errorGetAllParent = null;
      })
      .addCase(fetchParent.fulfilled, (state, action) => {
        state.loadingGetAllParent = false;
        state.getAllParent = action.payload;
      })
      .addCase(fetchParent.rejected, (state, action) => {
        state.loadingGetAllParent = false;
        state.errorGetAllParent = null;
      })
      .addCase(createParentAction.pending, (state) => {
        state.loadingCreate = true;
        state.errorCreate = null;
      })
      .addCase(createParentAction.fulfilled, (state, action) => {
        console.log(action.payload, "teeeee");
        state.loadingCreate = false;
        state.createParent = action.payload;
        if (action?.payload?.meta?.success === true) {
          // if (action?.payload?.data?.user_type == "parent") {
          //   state.getAllParent.data = [
          //     action.payload?.data,
          //     ...state?.getAllParent?.data,
          //   ];
          // }
          // state?.getAllParent?.data?.push(action.payload?.data);
        }
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(createParentAction.rejected, (state, action) => {
        state.loadingCreate = false;
        state.errorCreate = action.payload;
      })
      .addCase(editParentAction.pending, (state) => {
        state.loadingEdit = true;
        state.errorEdit = null;
      })
      .addCase(editParentAction.fulfilled, (state, action) => {
        state.loadingEdit = false;
        state.editedParent = action.payload;
    
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(editParentAction.rejected, (state, action) => {
        state.loadingEdit = false;
        state.errorEdit = action.payload;
      })
      .addCase(deleteParentAction.pending, (state) => {
        state.loadingDelete = true;
        state.errorDelete = null;
      })
      .addCase(deleteParentAction.fulfilled, (state, action) => {
        state.loadingDelete = false;
        state.deletedParent = action.payload;
        console.log("action.payload==>", action.payload);
        state.getAllParent.data = state?.getAllParent?.data?.filter(
          (groupId) => groupId?.id !== action?.payload?.data?.id
        );
        state.getAllParent.meta.pagination.totalUser -= 1;
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(deleteParentAction.rejected, (state, action) => {
        state.loadingDelete = false;
        state.errorDelete = action.payload;
      })
      .addCase(getUserByIdAction.pending, (state) => {
        state.loadingGetByParentId = true;
        state.errorGetByParentId = null;
      })
      .addCase(getUserByIdAction.fulfilled, (state, action) => {
        if (action?.payload?.meta?.success === true) {
          state.loadingGetByParentId = false;
          state.getByParentId = action.payload;
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.message,
            duration: 2,
          });
        }
      })
      .addCase(getUserByIdAction.rejected, (state, action) => {
        state.loadingGetByParentId = false;
        state.errorGetByParentId = action.payload;
      })
      .addCase(createParentByCSVAction.pending, (state) => {
        state.loadingCreateParentByCSV = true;
        state.errorCreateParentByCSV = null;
      })
      .addCase(createParentByCSVAction.fulfilled, (state, action) => {
        state.loadingCreateParentByCSV = false;
        state.createParentByCSV = action.payload;

        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(createParentByCSVAction.rejected, (state, action) => {
        state.loadingCreateParentByCSV = false;
        state.errorCreateParentByCSV = action.payload;
      })
      .addCase(fetchGuardian.pending, (state) => {
        state.loadingGetAllGuardian = true;
        state.errorGetAllGuardian = null;
      })
      .addCase(fetchGuardian.fulfilled, (state, action) => {
        state.loadingGetAllGuardian = false;
        state.getAllGuardian = action.payload;
      })
      .addCase(fetchGuardian.rejected, (state, action) => {
        state.loadingGetAllGuardian = false;
        state.errorGetAllGuardian = null;
      })
      .addCase(deleteGuardianAction.pending, (state) => {
        state.loadingDeleteGuardian = true;
        state.errorDeleteGuardian = null;
      })
      .addCase(deleteGuardianAction.fulfilled, (state, action) => {
        state.loadingDeleteGuardian = false;
        state.deletedGuardian = action.payload;
        state.getAllGuardian.data = state?.getAllGuardian?.data?.filter(
          (groupId) => groupId?.id !== action?.payload?.data?.id
        );
        state.getAllGuardian.meta.pagination.totalGuardians -= 1;
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(deleteGuardianAction.rejected, (state, action) => {
        state.loadingDeleteGuardian = false;
        state.errorDeleteGuardian = action.payload;
      })
  },
});

export default parentSlice.reducer;
export const { clearParentData } = parentSlice.actions;
