import { createSlice } from "@reduxjs/toolkit";
import { getAllChildForReportAction, getInitialReportAction, getDownloadReportAction,getAllTimesofReportAction } from "./actions";
import { notification } from "antd";

const initialState = {
  allChildForReport: [],
  loadingAllChildForReport: false,
  errorAllChild: null,

  initialReport: {},
  loadingInitialReport: false,
  errorInitialReport: null,

  downloadReport: {},
  loadingDownloadReport: false,
  errorDownloadReport: null,

  allTimesofReport: [],
  loadingAllTimesofReport: false,
  errorAllTimesofReport: null,
};
const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    clearInitialReport: (state) => {
      state.initialReport = {};
    },
    clearDownloadLink: (state) => {
      state.downloadReport = {};
    },
    clearAllTimesofReport: (state) => {
      state.allTimesofReport = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllChildForReportAction.pending, (state) => {
        state.loadingAllChildForReport = true;
        state.errorAllChild = null;
      })
      .addCase(getAllChildForReportAction.fulfilled, (state, action) => {
        state.loadingAllChildForReport = false;
        state.allChildForReport = action.payload;
      })
      .addCase(getAllChildForReportAction.rejected, (state, action) => {
        state.loadingAllChildForReport = false;
        state.errorAllChild = action.payload;
      })

      .addCase(getInitialReportAction.pending, (state) => {
        state.loadingInitialReport = true;
        state.errorInitialReport = null;
      })
      .addCase(getInitialReportAction.fulfilled, (state, action) => {
        state.loadingInitialReport = false;
        state.initialReport = action.payload;
      })
      .addCase(getInitialReportAction.rejected, (state, action) => {
        state.loadingInitialReport = false;
        state.errorInitialReport = action.payload;
      })
      .addCase(getDownloadReportAction.pending, (state) => {
        state.loadingDownloadReport = true;
        state.errorDownloadReport = null;
      })
      .addCase(getDownloadReportAction.fulfilled, (state, action) => {
        state.loadingDownloadReport = false;
        state.downloadReport = action.payload;
      })
      .addCase(getDownloadReportAction.rejected, (state, action) => {
        state.loadingDownloadReport = false;
        state.errorDownloadReport = action.payload;
      })
      .addCase(getAllTimesofReportAction.pending, (state) => {
        state.loadingAllTimesofReport = true;
        state.errorAllTimesofReport = null;
      })
      .addCase(getAllTimesofReportAction.fulfilled, (state, action) => {
        state.loadingAllTimesofReport = false;
        state.allTimesofReport = action.payload;
      })
      .addCase(getAllTimesofReportAction.rejected, (state, action) => {
        state.loadingAllTimesofReport = false;
        state.errorAllTimesofReport = action.payload;
      })

      
  },
});
export default reportSlice.reducer;
export const { clearInitialReport,clearDownloadLink,clearAllTimesofReport } =
reportSlice.actions;
