import { get, post, put, del } from "../../helpers/apiWrapper";

export const createClass = async (childData) => {
  const config = {
    data: childData,
  };
  return await post(`/team/create`, config);
};

// patch API for Edit child
export const editClass = async ({ formData, data }) => {
  const config = {
    data: formData,
  };
  return await put(`/team/${data?.id}`, config);
};

// get API for get all user
export const getdetailByClassId = async ({ data, parentId }) => {
  const config = {
    params: data,
  };

  return await get(`/user/${parentId?.id}`, config);
};

// delete class/team
export const deleteClass = async (data) => {
  return await del(`/team/${data?.id}`);
};

// get all class team
export const getAllClass = async (data) => {
  const config = {
    params: data,
  };
  return await get(`/team/getall`, config);
};

// get all member of that school
export const getAllMemberSchool = async (data) => {
  const config = {
    params: data,
  };
  return await post(`/team/getAllUnAssignedChildren`, config);
};

export const getAllPlayerForSpecificClass= async ({data,dataId})=>{
  const config = {
    params: data,
  };
  return await get(`/team/${dataId?.id}`, config);
}

export const removePlayerFromClass= async (data)=>{
  const config = {
    data: data,
  };
  return await post(`/team/removeChildFromTeamClass`, config);
}

export const getAllDetilsofClass= async (data)=>{

  return await post(`/team/getTeamClass/${data?.id}`);
}

//get the sample format 
export const getSampleCSVForTeamClass = async (data) => {
  const config={
   params: data
  }
   return await post(`/user/simple-csv`,config);
 };

 export const uploadTeamClassCSV = async (data) => {
  const config={
   data: data
  }
   return await post(`/team/upload-csv`,config);
 };


