import { createAsyncThunk } from "@reduxjs/toolkit";
import { createTreatment, editTreatment, getAllTreatment,deleteTreatment } from "../../api/treatmentPlanManagement";

// Thunk action for create treatment plan
export const createTreatmentPlanAction = createAsyncThunk(
  "treatment/create",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await createTreatment(userData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk action for edit treatment plan
export const editTreatmentPlanAction = createAsyncThunk(
  "treatment/edit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await editTreatment(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk action for get all treatment plan
export const getAllTreatmentPlanAction = createAsyncThunk(
  "treatment/getAll",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllTreatment(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Thunk action for delete treatment plan
export const deleteTreatmentPlanAction = createAsyncThunk(
  "treatment/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deleteTreatment(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);