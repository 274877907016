import { createAsyncThunk } from "@reduxjs/toolkit";
import { createInjury, editInjury, getAllInjury,deleteInjury } from "../../api/injuryManagement";

// Thunk action for create injury
export const createInjuryAction = createAsyncThunk(
  "injury/create",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await createInjury(userData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk action for edit injury
export const editInjuryAction = createAsyncThunk(
  "injury/edit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await editInjury(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk action for get all injury
export const getAllInjuryAction = createAsyncThunk(
  "injury/getAll",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllInjury(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Thunk action for delete treatment plan
export const deleteInjuryAction = createAsyncThunk(
  "injury/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deleteInjury(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);