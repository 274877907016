import { createSlice } from "@reduxjs/toolkit";
import {
  createTreatmentPlanAction,
  editTreatmentPlanAction,
  getAllTreatmentPlanAction,
  deleteTreatmentPlanAction,
} from "./actions";
import { notification } from "antd";

const initialState = {
  createdTreatmentPlan: {},
  loadingCreateTreatmentPlan: false,
  errorCreateTreatmentPlan: null,

  editedTreatmentPlan: {},
  loadingEditTreatmentPlan: false,
  errorEditTreatmentPlan: null,

  allTreatmentPlan: [],
  loadingAllTreatmentPlan: false,
  errorAllTreatmentPlan: null,

  deletedTreatmentPlan: {},
  loadingDeleteTreatmentPlan: false,
  errorDeleteTreatmentPlan: null,
};
const treatmentPlanSlice = createSlice({
  name: "treatmentplan",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createTreatmentPlanAction.pending, (state) => {
        state.loadingCreateTreatmentPlan = true;
        state.errorCreateTreatmentPlan = null;
      })
      .addCase(createTreatmentPlanAction.fulfilled, (state, action) => {
        state.loadingCreateTreatmentPlan = false;
        state.createdTreatmentPlan = action.payload;
        if (action?.payload?.meta?.success === true) {
          if (state.allTreatmentPlan?.meta?.pagination?.currentPage == 1) {
            state.allTreatmentPlan.data = [
              action.payload?.data,
              ...state?.allTreatmentPlan?.data,
            ];
          }
          state.allTreatmentPlan.meta.pagination.treatmentCategory += 1;
        }
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(createTreatmentPlanAction.rejected, (state, action) => {
        state.loadingCreateTreatmentPlan = false;
        state.errorCreateTreatmentPlan = action.payload;
      })
      .addCase(editTreatmentPlanAction.pending, (state) => {
        state.loadingEditTreatmentPlan = true;
        state.errorEditTreatmentPlan = null;
      })
      .addCase(editTreatmentPlanAction.fulfilled, (state, action) => {
        state.loadingEditTreatmentPlan = false;
        state.editedTreatmentPlan = action.payload;
        state.allTreatmentPlan.data = state.allTreatmentPlan.data.map((macro) =>
          macro.id === action.payload?.data?.id ? action.payload?.data : macro
        );
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(editTreatmentPlanAction.rejected, (state, action) => {
        state.loadingEditTreatmentPlan = false;
        state.errorEditTreatmentPlan = action.payload;
      })
      .addCase(getAllTreatmentPlanAction.pending, (state) => {
        state.loadingAllTreatmentPlan = true;
        state.errorAllTreatmentPlan = null;
      })
      .addCase(getAllTreatmentPlanAction.fulfilled, (state, action) => {
        state.loadingAllTreatmentPlan = false;
        state.allTreatmentPlan = action.payload;
      })
      .addCase(getAllTreatmentPlanAction.rejected, (state, action) => {
        state.loadingAllTreatmentPlan = false;
        state.errorAllTreatmentPlan = action.payload;
      })
      .addCase(deleteTreatmentPlanAction.pending, (state) => {
        state.loadingDeleteTreatmentPlan = true;
        state.errorDeleteTreatmentPlan = null;
      })
      .addCase(deleteTreatmentPlanAction.fulfilled, (state, action) => {
        state.loadingDeleteTreatmentPlan = false;
        state.deletedTreatmentPlan = action.payload;
        state.allTreatmentPlan.data = state.allTreatmentPlan.data.filter(
          (groupId) => groupId?.id !== action?.payload?.data?.id
        );
        state.allTreatmentPlan.meta.pagination.treatmentCategory -= 1;
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(deleteTreatmentPlanAction.rejected, (state, action) => {
        state.loadingDeleteTreatmentPlan = false;
        state.errorDeleteTreatmentPlan = action.payload;
      });
  },
});
export default treatmentPlanSlice.reducer;
