import { get, put } from "../../helpers/apiWrapper";


// patch API for Edit 
export const editCms = async ({ formData, data }) => {
  const config = {
    data: formData,
  };
  return await put(`/cms/edit/${data?.id}`, config);
};

// get API for get all user
export const getAllCms = async (data) => {
  const config = {
    params: data,
  };
  return await get(`/cms/all`, config);
};
export const getSingleCms = async (data) => {
  return await get(`/cms/${data?.id}`);
};
export const getCmsBySlug = async (data) => {
  const config = {
    data: data,
  };
  return await get(`/cms/slug`, config);
};


