import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllGuest,getLogHistoryForGuest ,getFullReportForGuest} from "../../api/guestManagement";




// Thunk action for get all guest
export const getAllGuestAction = createAsyncThunk(
  "guest/getAll",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllGuest(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
 // Thunk action for log history
export const getLogHistoryForGuestAction = createAsyncThunk(
  "guest/logHistory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getLogHistoryForGuest(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

 // Thunk action for log history
 export const getFullReportForGuestAction = createAsyncThunk(
  "guest/fullReport",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getFullReportForGuest(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);