import { Card } from "antd";
const isdark = true;

export const antConfig = {
    token: {
      colorPrimary: isdark ? '#ff8d26' :'#8CC63F',
      borderRadius: '4px',
    },
    components: {
      Card: {
        borderRadius: '8px', // Adjust the border radius for NTD card or any specific card component
      },
    },
   
}