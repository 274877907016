import { createSlice } from "@reduxjs/toolkit";
import {
  getAllPlayerAction,
  createPlayerAction,
  deletePlayerAction,
  editPlayerAction,
} from "./actions";
import { notification } from "antd";

const initialState = {
  createdPlayer: {},
  loadingCreatePlayer: false,
  errorCreatePlayer: null,

  editedPlayer: {},
  loadingEditPlayer: false,
  errorEditPlayer: null,

  getAllPLayers: {},
  loadingAllPLayers: false,
  errorAllPlayers: null,

  deletedPlayer: {},
  loadingDeletePlayer: false,
  errorDeletePlayer: null,
};
const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPlayerAction.pending, (state) => {
        state.loadingCreatePlayer = true;
        state.errorCreatePlayer = null;
      })
      .addCase(createPlayerAction.fulfilled, (state, action) => {
        state.loadingCreatePlayer = false;
        state.createdPlayer = action.payload;

        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(createPlayerAction.rejected, (state, action) => {
        state.loadingCreatePlayer = false;
        state.errorCreatePlayer = action.payload;
      })
      .addCase(getAllPlayerAction.pending, (state) => {
        state.loadingAllPLayers = true;
        state.errorAllPlayers = null;
      })
      .addCase(getAllPlayerAction.fulfilled, (state, action) => {
        state.loadingAllPLayers = false;
        state.getAllPLayers = action.payload;
      })
      .addCase(getAllPlayerAction.rejected, (state, action) => {
        state.loadingAllPLayers = false;
        state.errorAllPlayers = action.payload;
      })
      .addCase(deletePlayerAction.pending, (state) => {
        state.loadingDeletePlayer = true;
        state.errorDeletePlayer = null;
      })
      .addCase(deletePlayerAction.fulfilled, (state, action) => {
        state.loadingDeletePlayer = false;
        state.deletedPlayer = action.payload;

        state.getAllPLayers.children = state?.getAllPLayers?.children?.filter(
          (groupId) => groupId?.id !== action?.payload?.data?.id
        );
        state.getAllPLayers.meta.pagination.totalChildren -= 1;
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(deletePlayerAction.rejected, (state, action) => {
        state.loadingDeletePlayer = false;
        state.errorDeletePlayer = action.payload;
      })

      .addCase(editPlayerAction.pending, (state) => {
        state.loadingEditPlayer = true;
        state.errorEditPlayer = null;
      })
      .addCase(editPlayerAction.fulfilled, (state, action) => {
        state.loadingEditPlayer = false;
        state.editedPlayer = action.payload;
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(editPlayerAction.rejected, (state, action) => {
        state.loadingEditPlayer = false;
        state.errorEditPlayer = action.payload;
      });
  },
});
export default playerSlice.reducer;
