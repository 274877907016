import { get, post, patch, del, put } from "../../helpers/apiWrapper";


export const createTreatment = async (treatmentData) => {
  const config = {
    data: treatmentData,
  };
  return await post(`/treatment-category/create`, config);
};

export const editTreatment = async ({ formData, data }) => {
  const config = {
    data: formData,
  };
  return await put(`/treatment-category/${data?.id}`, config);
};

export const getAllTreatment = async (data) => {
  const config = {
    params: data,
  };
  return await get(`/treatment-category/all-treatment`, config);
};

export const deleteTreatment = async (data) => {
  return await del(`/treatment-category/${data?.id}`);
};



