import { createSlice } from "@reduxjs/toolkit";
import {
  getClassTeamAction,
  deleteTeamAction,
  getAllSchoolMemberAction,
  editClassAction,
  createClassAction,
  getPlayerSpecificClassAction,
  removePlayerAction,
  classDetailAction,
  getSampleCSVForTeamClassAction,
  postCSVForTeamClassAction,
} from "./actions";
import { notification } from "antd";

const initialState = {
  createdClass: {},
  loadingCreate: false,
  errorCreate: null,

  getAllClass: [],
  loadingAll: false,
  errorAll: null,

  deletedClass: {},
  loadingDelete: false,
  errorDelete: null,

  editedClass: {},
  loadingEdit: false,
  errorEdit: null,

  schoolMember: [],
  loadingSchoolMember: false,
  errorSchoolMember: null,

  getAllPlayerForClass: [],
  loadingAllPlayerForClass: false,
  errorAllPlayerForClass: null,

  playerRemoveFromClass: {},
  loadingplayerRemove: false,
  errorplayerRemove: null,

  classDetail: {},
  loadingClassDetail: false,
  errorClassDetail: null,

  csvFormatForTeam: {},
  loadingcsvFormatForTeam: false,
  errorCSVFormat: null,

  csvPostForTeam: {},
  loadingcsvPostForTeam: false,
  errorcsvPost: null,
};
const classSlice = createSlice({
  name: "class",
  initialState,
  reducers: {
    clearPostCSVDataForTeam: (state) => {
      state.csvPostForTeam = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createClassAction.pending, (state) => {
        state.loadingCreate = true;
        state.errorCreate = null;
      })
      .addCase(createClassAction.fulfilled, (state, action) => {
        state.loadingCreate = false;
        state.createdClass = action.payload;

        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(createClassAction.rejected, (state, action) => {
        state.loadingCreate = false;
        state.errorCreate = action.payload;
      })
      .addCase(getClassTeamAction.pending, (state) => {
        state.loadingAll = true;
        state.errorAll = null;
      })
      .addCase(getClassTeamAction.fulfilled, (state, action) => {
        state.loadingAll = false;
        state.getAllClass = action.payload;
      })
      .addCase(getClassTeamAction.rejected, (state, action) => {
        state.loadingAll = false;
        state.errorAll = action.payload;
      })
      .addCase(editClassAction.pending, (state) => {
        state.loadingEdit = true;
        state.errorEdit = null;
      })
      .addCase(editClassAction.fulfilled, (state, action) => {
        state.loadingEdit = false;
        state.editedClass = action.payload;

        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(editClassAction.rejected, (state, action) => {
        state.loadingEdit = false;
        state.errorEdit = action.payload;
      })
      .addCase(deleteTeamAction.pending, (state) => {
        state.loadingDelete = true;
        state.errorDelete = null;
      })
      .addCase(deleteTeamAction.fulfilled, (state, action) => {
        state.loadingDelete = false;
        state.deletedClass = action.payload;
        state.getAllClass.data = state?.getAllClass?.data?.filter(
          (groupId) => groupId?.id !== action?.payload?.data?.id
        );
        state.getAllClass.meta.pagination.totalItems -= 1;
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(deleteTeamAction.rejected, (state, action) => {
        state.loadingDelete = false;
        state.errorDelete = action.payload;
      })
      .addCase(getAllSchoolMemberAction.pending, (state) => {
        state.loadingSchoolMember = true;
        state.errorSchoolMember = null;
      })
      .addCase(getAllSchoolMemberAction.fulfilled, (state, action) => {
        state.loadingSchoolMember = false;
        state.schoolMember = action.payload;
      })
      .addCase(getAllSchoolMemberAction.rejected, (state, action) => {
        state.loadingSchoolMember = false;
        state.errorSchoolMember = action.payload;
      })
      .addCase(getPlayerSpecificClassAction.pending, (state) => {
        state.loadingAllPlayerForClass = true;
        state.errorAllPlayerForClass = null;
      })
      .addCase(getPlayerSpecificClassAction.fulfilled, (state, action) => {
        state.loadingAllPlayerForClass = false;
        state.getAllPlayerForClass = action.payload;
      })
      .addCase(getPlayerSpecificClassAction.rejected, (state, action) => {
        state.loadingAllPlayerForClass = false;
        state.errorAllPlayerForClass = action.payload;
      })
      .addCase(removePlayerAction.pending, (state) => {
        state.loadingplayerRemove = true;
        state.errorplayerRemove = null;
      })
      .addCase(removePlayerAction.fulfilled, (state, action) => {
        state.loadingplayerRemove = false;
        state.playerRemoveFromClass = action.payload;
        state.getAllPlayerForClass.data.assignments =
          state?.getAllPlayerForClass?.data?.assignments?.filter(
            (groupId) => groupId?.id !== action?.payload?.data?.id
          );
        state.getAllPlayerForClass.meta.pagination.totalItems -= 1;
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(removePlayerAction.rejected, (state, action) => {
        state.loadingplayerRemove = false;
        state.errorplayerRemove = action.payload;
      })
      .addCase(classDetailAction.pending, (state) => {
        state.loadingClassDetail = true;
        state.errorClassDetail = null;
      })
      .addCase(classDetailAction.fulfilled, (state, action) => {
        state.loadingClassDetail = false;
        state.classDetail = action.payload;
      })
      .addCase(classDetailAction.rejected, (state, action) => {
        state.loadingClassDetail = false;
        state.errorClassDetail = action.payload;
      })
      .addCase(getSampleCSVForTeamClassAction.pending, (state) => {
        state.loadingcsvFormatForTeam = true;
        state.errorCSVFormat = null;
      })
      .addCase(getSampleCSVForTeamClassAction.fulfilled, (state, action) => {
        state.loadingcsvFormatForTeam = false;
        state.csvFormatForTeam = action.payload;
      })
      .addCase(getSampleCSVForTeamClassAction.rejected, (state, action) => {
        state.loadingcsvFormatForTeam = false;
        state.errorCSVFormat = action.payload;
      })
      .addCase(postCSVForTeamClassAction.pending, (state) => {
        state.loadingcsvPostForTeam = true;
        state.errorcsvPost = null;
      })
      .addCase(postCSVForTeamClassAction.fulfilled, (state, action) => {
        state.loadingcsvPostForTeam = false;
        state.csvPostForTeam = action.payload;
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(postCSVForTeamClassAction.rejected, (state, action) => {
        state.loadingcsvPostForTeam = false;
        state.errorcsvPost = action.payload;
      });
  },
});
export default classSlice.reducer;
export const { clearPostCSVDataForTeam } = classSlice.actions;
