import { createAsyncThunk } from "@reduxjs/toolkit";
import {  editCms, getAllCms,getSingleCms,getCmsBySlug } from "../../api/cmsManagement";


// Thunk action for edit user
export const editCmsAction = createAsyncThunk(
  "cms/edit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await editCms(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk action for get all user
export const getAllCMSAction = createAsyncThunk(
  "cms/getAll",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllCms(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getSingleCMSAction = createAsyncThunk(
  "cms/single",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getSingleCms(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getSingleCMSBySlugAction = createAsyncThunk(
  "cms/singleBySlug",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getCmsBySlug(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
