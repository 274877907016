import { createAsyncThunk } from "@reduxjs/toolkit";
import { createFoundation, editFoundation, getAllFoundation, deleteFoundation,getFoundationDetail } from "../../api/foundation";


export const createFoundationAction = createAsyncThunk(
  "foundation/create",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await createFoundation(userData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk action for edit user
export const editFoundationAction = createAsyncThunk(
  "foundation/edit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await editFoundation(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk action for get all user
export const getAllFoundationAction = createAsyncThunk(
  "foundation/getAll",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllFoundation(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Thunk action for delelte employee
export const deleteFoundationAction = createAsyncThunk(
  "foundation/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deleteFoundation(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Thunk action for get a user
export const getFoundationDetailAction = createAsyncThunk(
  "foundation/detail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getFoundationDetail(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);