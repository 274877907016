// const config = {
//   API_URL: window.location.href.includes("webmobtech")
//     ? process.env.REACT_APP_DEVELOPMENT_API_URL
//     : window.location.href.includes("digidi")
//     ? process.env.REACT_APP_PRODUCTION_API_URL
//     : "",
//   IMAGE_URL: window.location.href.includes("webmobtech")
//     ? process.env.REACT_APP_DEVELOPMENT_API_URL
//     : window.location.href.includes("digidi")
//     ? process.env.REACT_APP_PRODUCTION_API_URL
//     : "",
// };

const config = {};

config.API_URL = process.env.REACT_APP_API_URL + "/";
config.IMAGE_URL = process.env.REACT_APP_API_URL;

export default config;
