import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createChild,
  deleteChild,
  editChild,
  getChildByParentId,
  getAllSchool,
  getIndivisualChildDetail,
  getSchoolForDropdown,
  getClubForDropdown

} from "../../api/child";

export const getChildAction = createAsyncThunk(
  "child/get",
  async (childData, { rejectWithValue }) => {
    try {
      const response = await getChildByParentId(childData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createChildAction = createAsyncThunk(
  "child/create",
  async (childData, { rejectWithValue }) => {
    try {
      const response = await createChild(childData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteChildAction = createAsyncThunk(
  "child/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deleteChild(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllSchoolAction = createAsyncThunk(
  "school/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAllSchool();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getChildDetailAction=createAsyncThunk(
  "child/detail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getIndivisualChildDetail(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)
export const editChildAction = createAsyncThunk(
  "child/edit",
  async (childData, { rejectWithValue }) => {
    try {
      const response = await editChild(childData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSchoolDropdownAction = createAsyncThunk(
  "school/dropdown",
  async (childData, { rejectWithValue }) => {
    try {
      const response = await getSchoolForDropdown(childData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getClubDropdownAction = createAsyncThunk(
  "club/dropdown",
  async (childData, { rejectWithValue }) => {
    try {
      const response = await getClubForDropdown(childData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
