import { createSlice } from "@reduxjs/toolkit";
import {
  createFoundationAction,
  editFoundationAction,
  getAllFoundationAction,
  deleteFoundationAction,
  getFoundationDetailAction,
} from "./actions";
import { notification } from "antd";

const initialState = {
  createdFoundation: {},
  loadingCreate: false,
  errorCreate: null,

  editedFoundation: {},
  loadingEdit: false,
  errorEdit: null,

  allFoundation: [],
  loadingAll: false,
  errorAll: null,

  deletedFoundation: {},
  loadingDelete: false,
  errorDelete: null,

  foundationDetail: {},
  loadingDetail: false,
  errorDetail: null,
};
const foundationSlice = createSlice({
  name: "foundation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createFoundationAction.pending, (state) => {
        state.loadingCreate = true;
        state.errorCreate = null;
      })
      .addCase(createFoundationAction.fulfilled, (state, action) => {
        state.loadingCreate = false;
        state.createdFoundation = action.payload;

        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(createFoundationAction.rejected, (state, action) => {
        state.loadingCreate = false;
        state.errorCreate = action.payload;
      })
      .addCase(editFoundationAction.pending, (state) => {
        state.loadingEdit = true;
        state.errorEdit = null;
      })
      .addCase(editFoundationAction.fulfilled, (state, action) => {
        state.loadingEdit = false;
        state.editedFoundation = action.payload;
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(editFoundationAction.rejected, (state, action) => {
        state.loadingEdit = false;
        state.errorEdit = action.payload;
      })
      .addCase(getAllFoundationAction.pending, (state) => {
        state.loadingAll = true;
        state.errorAll = null;
      })
      .addCase(getAllFoundationAction.fulfilled, (state, action) => {
        state.loadingAll = false;
        state.allFoundation = action.payload;
      })
      .addCase(getAllFoundationAction.rejected, (state, action) => {
        state.loadingAll = false;
        state.errorAll = action.payload;
      })
      .addCase(deleteFoundationAction.pending, (state) => {
        state.loadingDelete = true;
        state.errorDelete = null;
      })
      .addCase(deleteFoundationAction.fulfilled, (state, action) => {
        console.log(action?.payload);
        state.loadingDelete = false;
        state.deletedFoundation = action.payload;
        state.allFoundation.data = state.allFoundation.data.filter(
          (groupId) => groupId?.id !== action?.payload?.data?.id
        );
        state.allFoundation.meta.pagination.totalBlogs -= 1;
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(deleteFoundationAction.rejected, (state, action) => {
        state.loadingDelete = false;
        state.errorDelete = action.payload;
      })
      .addCase(getFoundationDetailAction.pending, (state) => {
        state.loadingDetail = true;
        state.errorDetail = null;
      })
      .addCase(getFoundationDetailAction.fulfilled, (state, action) => {
        state.loadingDetail = false;
        state.foundationDetail = action.payload;
      })
      .addCase(getFoundationDetailAction.rejected, (state, action) => {
        state.loadingDetail = false;
        state.errorDetail = action.payload;
      });
  },
});
export default foundationSlice.reducer;
