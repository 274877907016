import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllSchoolClubUser,getSchoolOrClubMemberDetail,removeMember } from "../../api/schoolclubUser";



// Thunk action for edit user
export const schoolOrClubMemberDetailAction = createAsyncThunk(
  "member/detail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getSchoolOrClubMemberDetail(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk action for get all user
export const getAllSchoolClubUserAction = createAsyncThunk(
  "user/getAllForSchool/club",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllSchoolClubUser(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//Thunk action for delelte employee
export const removeMemberAction = createAsyncThunk(
  "member/remove",
  async (data, { rejectWithValue }) => {
    try {
      const response = await removeMember(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);