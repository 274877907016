import { createSlice } from "@reduxjs/toolkit";
import {
  createSubCategoryAction,
  editSubCategoryAction,
  getAllSubcategoryAction,
  deleteSubCategoryAction,
} from "./actions";
import { notification } from "antd";

const initialState = {
  createdSubCategory: {},
  loadingSubCategory: false,
  errorSubCategory: null,

  editedSubCategory: {},
  loadingEditedSubCategory: false,
  errorEditedSubCategory: null,

  allSubCategory: [],
  loadingAllSubCategory: false,
  errorAllSubCategory: null,

  deletedSubCategory: {},
  loadingDeleteSubCategory: false,
  errorDeleteSubCategory: null,
};
const subCategorySlice = createSlice({
  name: "subCategory",
  initialState,
  reducers: {
    clearAllSubCategoryDetail: (state) => {
      state.allSubCategory = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSubCategoryAction.pending, (state) => {
        state.loadingSubCategory = true;
        state.errorSubCategory = null;
      })
      .addCase(createSubCategoryAction.fulfilled, (state, action) => {
        state.loadingSubCategory = false;
        state.createdSubCategory = action.payload;
        if (action?.payload?.meta?.success === true) {
          if (state.allSubCategory?.meta?.pagination?.currentPage == 1) {
            state.allSubCategory.data.subcategories = [
              action.payload.data,
              ...state.allSubCategory.data.subcategories,
            ];
          }
          
          state.allSubCategory.meta.pagination.totalSubcategories += 1;
        }
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(createSubCategoryAction.rejected, (state, action) => {
        state.loadingEditedSubCategory = false;
        state.errorSubCategory = action.payload;
      })
      .addCase(editSubCategoryAction.pending, (state) => {
        state.loadingEditedSubCategory = true;
        state.errorEditedSubCategory = null;
      })
      .addCase(editSubCategoryAction.fulfilled, (state, action) => {
        state.loadingEditedSubCategory = false;
        state.editedSubCategory = action.payload;
        state.allSubCategory.data.subcategories =
          state.allSubCategory.data.subcategories.map((macro) =>
            macro.id === action.payload?.data?.id ? action.payload?.data : macro
          );
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(editSubCategoryAction.rejected, (state, action) => {
        state.loadingEditedSubCategory = false;
        state.errorEditedSubCategory = action.payload;
      })
      .addCase(getAllSubcategoryAction.pending, (state) => {
        state.loadingAllSubCategory = true;
        state.errorAllSubCategory = null;
      })
      .addCase(getAllSubcategoryAction.fulfilled, (state, action) => {
        state.loadingAllSubCategory = false;
        state.allSubCategory = action.payload;
      })
      .addCase(getAllSubcategoryAction.rejected, (state, action) => {
        state.loadingAllSubCategory = false;
        state.errorAllSubCategory = action.payload;
      })
      .addCase(deleteSubCategoryAction.pending, (state) => {
        state.loadingDeleteSubCategory = true;
        state.errorDeleteSubCategory = null;
      })
      .addCase(deleteSubCategoryAction.fulfilled, (state, action) => {
        state.loadingDeleteSubCategory = false;
        state.deletedSubCategory = action.payload;
        state.allSubCategory.data.subcategories =
          state.allSubCategory.data.subcategories.filter(
            (groupId) => groupId?.id !== action?.payload?.data?.id
          );
        state.allSubCategory.meta.pagination.totalSubcategories -= 1;
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(deleteSubCategoryAction.rejected, (state, action) => {
        state.loadingDeleteSubCategory = false;
        state.errorDeleteSubCategory = action.payload;
      });
  },
});
export default subCategorySlice.reducer;
export const { clearAllSubCategoryDetail } =
subCategorySlice.actions;
