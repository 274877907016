import { get, post, put, del } from "../../helpers/apiWrapper";

export const createPlayer = async (childData) => {
  const config = {
    data: childData,
  };
  return await post(`/child/create`, config);
};

// patch API for Edit child
export const editPlayer = async ({ formData, data }) => {
  const config = {
    data: formData,
  };
  return await put(`/child/${data?.id}`, config);
};

// get API for get all user
export const getAllPlayer = async ({data,parentId}) => {
  const config = {
    params: data,
  };

  return await get(`/child/getAllChildBySchool/${parentId?.id}`, config);
};

// delete player
export const deletePlayer= async (data) => {
 
  return await del(`/child/${data?.id}`);
};


