import { createSlice } from "@reduxjs/toolkit";
import {
  createFAQAction,
  editFAQAction,
  getAllFAQAction,
  deleteFAQAction,
} from "./actions";
import { notification } from "antd";

const initialState = {
  createdFAQ: {},
  loadingCreateFAQ: false,
  errorCreateFAQ: null,

  editedFAQ: {},
  loadingEditFAQ: false,
  errorEditFAQ: null,

  allFAQ: { data: [] }, // Make sure `allFAQ` has a `data` array
  loadingAllFAQ: false,
  errorAllFAQ: null,

  deletedFAQ: {},
  loadingDeleteFAQ: false,
  errorDeleteFAQ: null,
};

const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createFAQAction.pending, (state) => {
        state.loadingCreateFAQ = true;
        state.errorCreateFAQ = null;
      })
      .addCase(createFAQAction.fulfilled, (state, action) => {
        state.loadingCreateFAQ = false;
        state.createdFAQ = action.payload;
        if (action?.payload?.meta?.success === true) {
          state.allFAQ.data = [action.payload.data, ...state.allFAQ.data];
        }
        notification[action?.payload?.meta?.success ? "success" : "error"]({
          message: action.payload.meta.success ? "Success" : "Error",
          description: action.payload.meta.message,
          duration: 2,
        });
      })
      .addCase(createFAQAction.rejected, (state, action) => {
        state.loadingCreateFAQ = false;
        state.errorCreateFAQ = action.payload;
      })
      .addCase(editFAQAction.pending, (state) => {
        state.loadingEditFAQ = true;
        state.errorEditFAQ = null;
      })
      .addCase(editFAQAction.fulfilled, (state, action) => {
        state.loadingEditFAQ = false;
        state.editedFAQ = action.payload;
        state.allFAQ.data = state.allFAQ.data.map((faq) =>
          faq.id === action.payload.data.id ? action.payload.data : faq
        );
        notification[action?.payload?.meta?.success ? "success" : "error"]({
          message: action.payload.meta.success ? "Success" : "Error",
          description: action.payload.meta.message,
          duration: 2,
        });
      })
      .addCase(editFAQAction.rejected, (state, action) => {
        state.loadingEditFAQ = false;
        state.errorEditFAQ = action.payload;
      })
      .addCase(getAllFAQAction.pending, (state) => {
        state.loadingAllFAQ = true;
        state.errorAllFAQ = null;
      })
      .addCase(getAllFAQAction.fulfilled, (state, action) => {
        state.loadingAllFAQ = false;
        state.allFAQ = action.payload;
      })
      .addCase(getAllFAQAction.rejected, (state, action) => {
        state.loadingAllFAQ = false;
        state.errorAllFAQ = action.payload;
      })
      .addCase(deleteFAQAction.pending, (state) => {
        state.loadingDeleteFAQ = true;
        state.errorDeleteFAQ = null;
      })
      .addCase(deleteFAQAction.fulfilled, (state, action) => {
        state.loadingDeleteFAQ = false;
        state.deletedFAQ = action.payload;
        state.allFAQ.data = state.allFAQ.data.filter(
          (faq) => faq.id !== action.payload.data.id
        );
        notification[action?.payload?.meta?.success ? "success" : "error"]({
          message: action.payload.meta.success ? "Success" : "Error",
          description: action.payload.meta.message,
          duration: 2,
        });
      })
      .addCase(deleteFAQAction.rejected, (state, action) => {
        state.loadingDeleteFAQ = false;
        state.errorDeleteFAQ = action.payload;
      });
  },
});

export default faqSlice.reducer;