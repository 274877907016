import { get, post, put, del } from "../../helpers/apiWrapper";

// POST API for get the list of user
export const getAllSchoolClubUser = async ({data,dataId}) => {
  const config = {
    params: data,
  };
  return await post(`/user/get-all-subuser/${dataId?.id}`, config);
};

export const getSchoolOrClubMemberDetail = async (data) => {
  return await get(`/child/${data?.id}`);
};

export const removeMember = async (data) => {
  const config = {
    data: data,
  };
  return await post(`/user/unlink-sub-user-to-school-clubs`,config);
};
