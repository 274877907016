import React, { useState, useEffect } from "react";
import "./HeaderNav.scss";

import {
  Avatar,
  Badge,
  Button,
  Col,
  Drawer,
  Dropdown,
  Empty,
  Layout,
  notification,
  Row,
  Space,
  theme,
  Input,
  Modal,
  Divider,
} from "antd";
import {
  BellFilled,
  MessageFilled,
  UserOutlined,
  LogoutOutlined,
  SearchOutlined,
  RightOutlined,
  SettingOutlined,
  AndroidOutlined,
  AppleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { messageNotification } from "../../../utils/dummy-data";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { items } from "../../../constants";
import { removeUser } from "../../../services/store/Authentication/slice";
import {
  fetchUser,
  fetchVersion,
} from "../../../services/store/GetUser/actions";
import { clearuserDetailData } from "../../../services/store/GetUser/slice";
import config from "../../../utils/url-config";
import { dcryptedData } from "../../../utils/commonFunction";

const { Header } = Layout;

const HeaderNav = () => {
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [searchedItems, setsearchedItems] = useState(null);
  const [notificationCount, setNotificationCount] = useState(
    messageNotification?.length
  );
  const navigate = useNavigate();
  const { isDark } = useSelector((state) => state.darkMode);
  const { userDetail, updatedDetail, version } = useSelector(
    (state) => state.getUser
  ); // get the data after login
  const dispatch = useDispatch();
  console.log(version, "version");
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const profileItems = [
    {
      label: "Profile",
      key: "1",
      icon: (
        <UserOutlined
          style={{ fontSize: "14px" }}
          data-testid="profile-test-id"
        />
      ),
      className: "header-profile-dropdown",
    },
    {
      label: "Logout",
      key: "2",
      icon: <LogoutOutlined style={{ fontSize: "14px" }} />,
      style: { color: "#ee4b4f" },
      className: "headerLogoutDropdown",
    },
  ];

  const onClose = () => {
    setOpen(false);
  };

  const onClickClear = () => {
    setNotificationCount(0);
  };

  const onClick = ({ key }) => {
    if (key === "1") {
      navigate("/profile");
    }
    if (key === "2") {
      dispatch(removeUser()); // remove the user after logout
      localStorage.removeItem("BASELINE_TOKEN"); // remove the token after logout
      localStorage.removeItem("id"); // remove the id after logout
      localStorage.removeItem("data");
      localStorage.removeItem("dataForProfile");
      dispatch(clearuserDetailData());

      navigate("/login");
    }
  };

  const NotificationHeader = () => {
    return (
      <Row>
        <Col xs={12} className="fs-18 d-flex items-center">
          Notification
        </Col>
        <Col xs={12} className="d-flex justify-end">
          <Button
            type="link"
            onClick={onClickClear}
            data-testid="clear-notification"
          >
            Clear
          </Button>
        </Col>
      </Row>
    );
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onSearch = (e) => {
    if (e?.target?.value?.length > 2) {
      const result = [];
      items?.map((item) => {
        if (item?.isSubmenu === false) {
          if (
            item?.label
              ?.toLowerCase()
              ?.includes(e?.target?.value?.toLowerCase()) ||
            item?.route
              ?.toLowerCase()
              ?.includes(e?.target?.value?.toLowerCase())
          ) {
            result?.push({
              key: item?.key,
              route: item?.route,
              icon: item?.icon,
              label: item?.label,
              isSubmenu: item?.isSubmenu,
              description: item?.description,
            });
          }
        }
        if (item?.isSubmenu === true) {
          item?.SubMenu?.map((sub) => {
            if (
              sub?.label
                ?.toLowerCase()
                .includes(e?.target?.value?.toLowerCase()) ||
              sub?.route
                ?.toLowerCase()
                .includes(e?.target?.value?.toLowerCase())
            ) {
              result?.push({
                key: sub?.key,
                route: sub?.route,
                icon: item?.icon,
                label: sub?.label,
                isSubmenu: item?.isSubmenu,
                description: sub?.description,
              });
            }
          });
        }
        return result;
      });
      setsearchedItems(result);
    } else {
      setsearchedItems(null);
    }
  };

  const onClickSearchItem = (item) => {
    setIsModalOpen(false);
    navigate(item?.route || "/");
  };
  const handleSettingsCancel = () => {
    setIsSettingsModalOpen(false);
  };

  // to fetch the version
  // useEffect(() => {
  //     dispatch(fetchVersion());
  // }, []);
  return (
    <Header style={{ padding: 0 }}>
      <Modal
        title={<h3 className="m-0">App Information</h3>}
        open={isSettingsModalOpen}
        onCancel={handleSettingsCancel}
        footer={null}
      >
         <Divider className="m-0"/>
        <div className="flex items-center ">
         
          <AndroidOutlined style={{ fontSize: "24px", marginRight: "8px" }} className="text-primary" />
          <h4>Latest Android version:</h4>
          <h4 className="ml-2">{version?.data?.android?.version_no || "NA"}</h4>
        </div>
        <div className="flex items-center">
          <AppleOutlined style={{ fontSize: "24px", marginRight: "8px" }} className="text-primary"/>
          <h4 style={{ margin: "0px" }}>Latest iOS version:</h4>
          <h4 style={{ margin: "0px" }} >
            <div className="ml-2">{version?.data?.ios?.version_no || "NA"}</div>
            
          </h4>
        </div>
      </Modal>
      <Row data-testid="main-header-id">
        <Col xs={24} sm={24} md={24}>
          <div className="header-right-main flex justify-end">
            {/* <div>
              <InfoCircleOutlined 
                style={{ color: "white", fontSize: "16px", cursor: "pointer" }}
                onClick={() => setIsSettingsModalOpen(true)}
              />
            </div> */}
            <div className="header-right-profile pl-md-5 pl-3 pr-5 ml-md-1 mr-1 text-white cursor-pointer">
              <Dropdown
                menu={{
                  items: profileItems,
                  onClick,
                }}
              >
                <div
                  onClick={(e) => e.preventDefault()}
                  data-testid="profileDropdown"
                >
                  <span className="pr-2">
                    <span className="text-bolder">
                      {" "}
                      {dcryptedData(userDetail?.first_name)}{" "}
                      {dcryptedData(userDetail?.last_name)}
                    </span>
                  </span>
                  {userDetail?.photo ? (
                    <Avatar
                      src={`${config?.IMAGE_URL}${userDetail?.photo}`}
                      size={50}
                    />
                  ) : (
                    // <img src={`${process.env.REACT_APP_IMAGE_URL}${userDetail?.profile_photo}`}/>
                    <Avatar
                      size={40}
                      style={{
                        backgroundColor: "#fff4cb",
                        color: "#ffd333",
                      }}
                      className="text-capitalize"
                    >
                      <span className="fs-24">{`${userDetail?.first_name?.[0]}`}</span>
                    </Avatar>
                  )}
                </div>
              </Dropdown>
            </div>
          </div>
        </Col>
      </Row>
      <Modal
        data-testid="modal-of-global-search"
        title={
          <Row className="rounded">
            <Col xs={24} md={22}>
              <Input
                size="large"
                placeholder="Search pages"
                bordered={false}
                prefix={<UserOutlined className="mr-2 ml-1" />}
                onChange={onSearch}
                data-testid="header-search-onchange-input"
                aria-label="global-search-input"
              />
            </Col>
          </Row>
        }
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        className="searchModal"
      >
        <div className="pb-1">
          <div className="search-body pb-1 pl-3 pr-3">
            {searchedItems?.length > 0 ? (
              searchedItems?.map((item) => (
                <div
                  className={`searchItem p-2 rounded mb-2 cursor-pointer  shadow ${
                    isDark ? "bg-custom" : ""
                  }`}
                  onClick={() => onClickSearchItem(item)}
                  data-testid=""
                >
                  <Row align={"middle"}>
                    <Col xs={22} md={22} lg={22}>
                      <div className="fs-16 ">{item?.label} </div>
                      <div className="text-gray">{item?.description}</div>
                    </Col>
                    <Col
                      xs={2}
                      md={2}
                      lg={2}
                      className="d-flex justify-content-end align-items-center"
                    >
                      <RightOutlined />
                    </Col>
                  </Row>
                </div>
              ))
            ) : (
              <div className="d-flex items-center h-full justify-center ">
                <Empty description={`No search found`} className="mb-5" />
              </div>
            )}
          </div>
        </div>
      </Modal>

      <Drawer
        title={<NotificationHeader />}
        className="drawer-notification-main"
        placement="right"
        onClose={onClose}
        open={open}
      >
        {notificationCount > 0 ? (
          messageNotification?.map((item) => {
            return (
              <div
                className={`${
                  isDark ? "card-dark" : ""
                } shadow-md p-3 rounded mb-4`}
                key={item?.id}
              >
                <Row className="mb-4">
                  <Col xs={3}>
                    {item?.type === "message" ? (
                      <MessageFilled
                        style={{ fontSize: "20px", color: `${colorPrimary}` }}
                      />
                    ) : (
                      <BellFilled
                        style={{
                          fontSize: "20px",
                          color: "#ee4b4f",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </Col>
                  <Col xs={15} className="fs-16 text-gray text-bold">
                    {item?.title}
                  </Col>
                  <Col xs={6} className="text-gray text-bold text-end">
                    {item?.time}
                  </Col>
                </Row>
                <Row className={`mb-2 fs-16 ${isDark ? "text-white" : ""}`}>
                  {item?.description}
                </Row>
                <Row className="text-gray text-bold">{item?.message}</Row>
              </div>
            );
          })
        ) : (
          <div
            className="d-flex items-center h-full justify-center"
            data-testid="notification-empty"
          >
            <Empty description={`Don't have any notification`} />
          </div>
        )}
      </Drawer>
    </Header>
  );
};

export default HeaderNav;
